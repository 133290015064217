// // src/components/Login.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [message, setMessage] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8000/api/token/', {
//         email,
//         password,
//       });
//       localStorage.setItem('accessToken', response.data.access);
//       localStorage.setItem('refreshToken', response.data.refresh);
//       setMessage('Logged in successfully');
//       navigate('/wallet');
//     } catch (error) {
//       setMessage('Invalid email or password');
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Email:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         {message && <p>{message}</p>}
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8000/api/token/', {
        email,
        password,
      });
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      setMessage('Logged in successfully');
      navigate('/wallet');
    } catch (error) {
      setMessage('Invalid email or password');
    }
  };

  return (
    <>
      <section className="ezy__signin14 light py-11 bg-white dark:bg-[#0b1727] text-white relative overflow-hidden z-10">
        <div className="container px-4 mx-auto">
          {/* Button trigger modal */}
          <button
            className="bg-blue-600 text-white hover:bg-opacity-90 py-2 px-4 rounded"
            onClick={handleShow}
          >
            Log In
          </button>
        </div>
      </section>

      <div className={show ? "" : "hidden"}>
        <div className="bg-white dark:bg-gray-900 text-zinc-900 dark:text-white overflow-hidden relative max-w-[90vw] sm:max-w-[80vw] lg:max-w-[45vw] xl:max-w-[30vw] rounded mx-auto my-14 p-10">
          <button
            className="absolute right-0 top-0 mt-4 mr-4 z-20"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="p-0 relative">
            <div className="grid grid-cols-12 py-4 justify-center">
              <div className="col-span-12">
                <div className="md:p-5">
                  <div className="flex justify-center items-center pb-3">
                    <img
                      src="https://cdn.easyfrontend.com/pictures/testimonial/testimonial_square_1.jpeg"
                      alt=""
                      className="rounded-full border-0"
                      width="100"
                      height="100"
                    />
                  </div>
                  <h2 className="font-bold text-3xl leading-6 text-center p-2 mt-4 mb-2">
                    Log In
                  </h2>
                  <form onSubmit={handleSubmit} className="mt-4">
                    <div className="mb-4">
                      <input
                        type="email"
                        className="w-full bg-transparent min-h-[48px] leading-10 px-4 p-2 rounded-md outline-none border border-gray-400 dark:border-slate-700 focus:border-blue-600 dark:focus:border-blue-700"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="password"
                        className="w-full bg-transparent min-h-[48px] leading-10 px-4 p-2 rounded-md outline-none border border-gray-400 dark:border-slate-700 focus:border-blue-600 dark:focus:border-blue-700"
                        id="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {message && <p className="text-red-500 text-center">{message}</p>}
                    <button
                      type="submit"
                      className="font-semibold bg-blue-500 text-white py-3 px-8 rounded w-full mt-6"
                    >
                      Log In
                    </button>
                    <div className="text-center mt-5 pt-4">
                      <p className="mb-0">
                        No Account?{" "}
                        <a href="/register" className="text-blue-600">
                          Create An Account
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
