import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import AboutImage from '../../assets/images/background/About.jpeg';
import {
    faCamera,
    faCannabis,
    faRandom,
} from "@fortawesome/free-solid-svg-icons";

const features = [
    {
        icon: faCannabis,
        title: "Real-Time Updates",
        desc: "We Provide Up-To-Date Information On Profit Rates From Various Banks, Ensuring You Always Have Access To The Best Options.",
    },
    {
        icon: faRandom,
        title: "Comprehensive Comparisons",
        desc: "Our Platform Allows You To Compare Profit Rates Across Different Time Periods And Banking Institutions, Making It Easier To Find The Perfect Match For Your Financial Needs.",
    },
    {
        icon: faCamera,
        title: "Expert Insights",
        desc: "Our Financial Experts Analyze Market Trends And Offer Insights To Help You Make Confident Decisions.",
    },
];

const FeatureItem = ({ feature }) => {
    return (
        <div className="flex relative p-4 md:p-6 mb-4 lg:mb-6">
            <div className="text-[#00416A] rounded-full text-3xl mb-6 mr-6">
                <FontAwesomeIcon icon={feature.icon} />
            </div>
            <div>
                <h4 className="text-2xl font-medium mb-4">{feature.title}</h4>
                <p className="opacity-70">{feature.desc}</p>
            </div>
        </div>
    );
};

FeatureItem.propTypes = {
    feature: PropTypes.object.isRequired,
};

const Feature25 = () => {
    return (
        <section className="py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="flex max-w-3xl justify-center text-center mb-12 mx-auto">
                    <div>
                        <h2 className="text-2xl leading-none font-bold md:text-[45px] mb-6 text-[#00416A]">
                            What Is Profit Compare
                        </h2>
                        <p className="text-lg mb-6">
                        At Profit Comparison, our mission is to empower individuals and businesses with the financial insights they need to make informed decisions.
                         Navigating the vast array of banking options can be overwhelming, and we are here to simplify that process. 
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 justify-end relative">
                    <div className="col-span-12 lg:col-span-6">
                        <div>
                            {features.map((feature, i) => (
                                <FeatureItem feature={feature} key={i} />
                            ))}
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                        <div className="h-full">
                            <div
                                className="bg-center bg-no-repeat bg-cover min-h-[350px] w-full lg:float-right lg:h-full"
                                style={{
                                    backgroundImage: `url(${AboutImage})`, // Use the imported image
                                    backgroundSize: 'cover', // Optional styling
                                    backgroundRepeat: 'no-repeat', // Optional styling
                                    backgroundPosition: 'center', // Optional styling
                                    height: '80vh', // Example height, adjust as needed
                                    width: '100%', // Example width, adjust as needed
                                    borderRadius: '15px',
                                  }}
                            ></div>
                            <div className="relative mx-12 -mt-12 rounded-xl overflow-hidden shadow-xl z-20 lg:absolute lg:top-12 lg:bottom-12 lg:left-[75%] lg:right-12 lg:m-0 lg:mt-10">
                                <div
                                    className="bg-center bg-no-repeat bg-cover rounded-xl p-0 pb-28"
                                    style={{
                                        backgroundImage:
                                            "url(https://cdn.easyfrontend.com/pictures/shape-1.png)",
                                    }}
                                >
                                    <div className="text-white rounded-xl bg-[#00416A] dark:bg-slate-800 leading-relaxed px-4 py-6 lg:px-6 lg:py-12">
                                        <h4 className="text-2xl font-medium mb-4 text-[#fff]">Profit Compare</h4>
                                        <p className="opacity-80">
                                            It{"’"}s easier to reach your savings goals when you have
                                            the right savings account. Take a look and find the right
                                            one for you!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Feature25;
