import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import PageTitle from '../components/pagetitle'; // Assuming you have a PageTitle component

const ProductForm = () => {
    const [formData, setFormData] = useState({
        bank_name: '',
        acc_type: '',
    });
    const [comparisonResults, setComparisonResults] = useState([]);
    const [bankOptions, setBankOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    // Fetch bank options on component mount
    useEffect(() => {
        const fetchBanks = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://profit-backend.onrender.com/api/banks/');
                const options = response.data.map(bank => ({
                    value: bank.name,
                    label: bank.name,
                }));
                setBankOptions(options);
            } catch (error) {
                console.error("Error fetching bank options:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBanks();
    }, []);

    // Fetch and compare data whenever formData changes
    useEffect(() => {
        const fetchAndCompare = async () => {
            if (!formData.bank_name || !formData.acc_type) return;

            setLoading(true);
            try {
                const response = await axios.get('https://profit-backend.onrender.com/api/banks/');
                const selectedBank = response.data.find(product => product.name.toLowerCase() === formData.bank_name.toLowerCase());

                if (!selectedBank) {
                    setComparisonResults([]);
                    setMessage('Bank not found.');
                    return;
                }

                const selectedBankRate = parseFloat(selectedBank.profitrate) || 0;
                const selectedBank1MonthRate = parseFloat(selectedBank.profitrate_1month) || 0;
                const selectedBank3MonthRate = parseFloat(selectedBank.profitrate_3month) || 0;
                const selectedBankAnnualRate = parseFloat(selectedBank.profitrate_annual) || 0;

                let comparisonResults = [];

                if (formData.acc_type === 'Savings') {
                    comparisonResults = response.data
                        .filter(product => product.name.toLowerCase() !== formData.bank_name.toLowerCase())
                        .filter(product => parseFloat(product.profitrate) > selectedBankRate)
                        .map(product => ({
                            name: product.name,
                            profitrate: parseFloat(product.profitrate),
                            difference: (parseFloat(product.profitrate) - selectedBankRate).toFixed(2),
                            selectedBankRate,
                        }));

                    comparisonResults.sort((a, b) => b.profitrate - a.profitrate);
                } else if (formData.acc_type === 'Current') {
                    comparisonResults = [{ name: 'No profit', profitrate: 0, difference: 0, selectedBankRate: 0 }];
                } else if (formData.acc_type === 'Fixed Deposit') {
                    comparisonResults = response.data
                        .filter(product => product.name.toLowerCase() !== formData.bank_name.toLowerCase())
                        .map(product => {
                            const profitrate_1month = parseFloat(product.profitrate_1month) || 0;
                            const profitrate_3month = parseFloat(product.profitrate_3month) || 0;
                            const profitrate_annual = parseFloat(product.profitrate_annual) || 0;

                            return {
                                name: product.name,
                                profitrate_1month,
                                profitrate_3month,
                                profitrate_annual,
                                selectedBank1MonthRate,
                                selectedBank3MonthRate,
                                selectedBankAnnualRate,
                            };
                        })
                        .filter(product => product.profitrate_1month > selectedBank1MonthRate);

                    comparisonResults.sort((a, b) => b.profitrate_1month - a.profitrate_1month); // Ensure sorting is correct
                }

                if (comparisonResults.length === 0) {
                    setMessage('Your selected bank is offering the highest rate.');
                } else {
                    setMessage('');
                }

                setComparisonResults(comparisonResults);
            } catch (error) {
                console.error("Error fetching products:", error);
                setMessage('Error fetching comparison data.');
            } finally {
                setLoading(false);
            }
        };

        fetchAndCompare();
    }, [formData.bank_name, formData.acc_type]);

    const handleSelectChange = (selectedOption, action) => {
        setFormData(prevData => ({ ...prevData, [action.name]: selectedOption.value }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        if (name === 'acc_type') {
            setComparisonResults([]); // Clear results when account type changes
        }
    };

    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <PageTitle heading='Compare' title='Compare' />
            <section className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <div className="contact-main">
                                <div className="block-text center">
                                    <h3 className="heading">Start Comparing your Profit Rates</h3>
                                    <p className="desc fs-20">Feel free to compare</p>
                                </div>
                                <form className="relative z-10">
                                    <div className="form-group">
                                        <label>Bank Name</label>
                                        <Select
                                            className="form-control"
                                            name="bank_name"
                                            value={bankOptions.find(option => option.value === formData.bank_name)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'bank_name' })}
                                            options={bankOptions}
                                            placeholder="Select a bank"
                                            isSearchable={true}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Account Type</label>
                                        <select
                                            className="form-control"
                                            name="acc_type"
                                            value={formData.acc_type}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select an account type</option>
                                            <option value="Savings">Savings</option>
                                            <option value="Current">Current</option>
                                            <option value="Fixed Deposit">Fixed Deposit</option>
                                        </select>
                                    </div>
                                </form>
                                <section className="coin-list relative z-0">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="block-text">
                                                    <h3 className="heading">Bank Profit-rates</h3>
                                                </div>
                                                <div className="coin-list__main">
                                                    {loading ? <p>Loading...</p> : (
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Bank Name</th>
                                                                    {formData.acc_type === 'Fixed Deposit' ? (
                                                                        <>
                                                                            <th scope="col">1 Month Rate</th>
                                                                            <th scope="col">3 Month Rate</th>
                                                                            <th scope="col">Annual Rate</th>
                                                                            <th scope="col">{formData.bank_name} 1 Month Rate</th>
                                                                            <th scope="col">{formData.bank_name} 3 Month Rate</th>
                                                                            <th scope="col">{formData.bank_name} Annual Rate</th>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <th scope="col">Profit Rate</th>
                                                                            <th scope="col">Difference</th>
                                                                            <th scope="col">{formData.bank_name} Profit Rate</th>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {comparisonResults.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan={formData.acc_type === 'Fixed Deposit' ? 6 : 3} style={{ textAlign: 'center' }}>
                                                                            {message || 'No results found'}
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    comparisonResults.map((result, index) => (
                                                                        <tr key={index}>
                                                                            <td>{result.name}</td>
                                                                            {formData.acc_type === 'Fixed Deposit' ? (
                                                                                <>
                                                                                    <td>{result.profitrate_1month}%</td>
                                                                                    <td>{result.profitrate_3month}%</td>
                                                                                    <td>{result.profitrate_annual}%</td>
                                                                                    <td>{result.selectedBank1MonthRate}%</td>
                                                                                    <td>{result.selectedBank3MonthRate}%</td>
                                                                                    <td>{result.selectedBankAnnualRate}%</td>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <td>{result.profitrate}%</td>
                                                                                    <td>{result.difference}%</td>
                                                                                    <td>{result.selectedBankRate}%</td>
                                                                                </>
                                                                            )}
                                                                        </tr>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProductForm;
