import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const faqList = [
	{
		isActive: true,
		question: "Where should I invest my funds for the highest profit?",
		answer:
			"To find the best investment options for high profits, I recommend using our calculators to compare the profit rates of various banks. Please consult your financial consultant for personalized advice and ensure you have all necessary information before shifting your funds.",
	},
	{
		isActive: false,
		question: "What are the current profit rates for savings accounts?",
		answer:
			"The current profit rates for savings accounts vary by bank. You can view up-to-date comparisons using our website's calculators. Please consult your financial consultant and verify the rates before making any decisions.",
	},
	{
		isActive: false,
		question: "How can I maximize my returns on a fixed deposit?",
		answer:
			"To maximize returns on a fixed deposit, compare the profit rates offered by different banks using our calculators. Please consult your financial consultant to choose the best option and gather all relevant information before shifting your funds.",
	},
	{
		isActive: false,
		question: "Is it safe to transfer large sums of money to a new bank?",
		answer:
			"Transferring large sums of money should always be done with caution. Ensure you choose a reputable bank. Consult your financial consultant for advice and make sure you have all the necessary information before proceeding.",
	},
];

const FaqItem = ({ faq }) => {
	const [isOpen, setIsOpen] = useState(faq.isActive || false);

	const toggleFaq = () => setIsOpen(!isOpen);

	return (
		<div className={`${isOpen && "active"} rounded-lg`}>
			<a
				href="#!"
				className="btn p-4 lg:p-6 w-full text-start flex justify-between items-center cursor-pointer"
				onClick={toggleFaq}
				style={{ color: "#00416A" }}
			>
				<span>{faq.question}</span>
				<FontAwesomeIcon
					icon={isOpen ? faChevronUp : faChevronDown}
					style={{ color: "#00416A" }}
				/>
			</a>
			<div
				className={`${
					isOpen ? "block" : "hidden"
				} p-4 lg:p-6 bg-white shadow dark:shadow-none dark:bg-[#1E2735] rounded-xl`}
			>
				<p className="opacity-50">{faq.answer}</p>
			</div>
		</div>
	);
};

FaqItem.propTypes = {
	faq: PropTypes.object.isRequired,
};

const Faq8 = () => {
	return (
		<section className="ezy__faq8 light py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
			<div className="container px-16 md:px-8 lg:px-28">
				<div className="grid grid-cols-12 justify-between gap-6">
					<div className="col-span-12 md:col-span-5">
						<h2
							className="font-bold text-[25px] md:text-[45px] leading-none mb-6"
							style={{ color: "#00416A" }}
						>
							Frequently Asked Questions
						</h2>
						<p className="text-lg opacity-70">
                        At Profit Comparison, our mission is to empower individuals and businesses with the financial insights they need to make informed decisions. Navigating the vast array of banking options can be overwhelming, and we are here to simplify that process. Our team of experts diligently curates and updates the latest profit rates, helping you find the best savings and investment opportunities.
						</p>
						{/* <button
							className="bg-transparent hover:bg-[#00416A] border border-[#00416A] hover:text-white rounded transition mt-6 lg:mt-12 px-7 py-3 text-[#00416A]"
						href="/faq">
							View All FAQ's
						</button> */}
					</div>
					<div className="col-span-12 md:col-span-6 md:col-start-7">
						{faqList.map((faq, i) => (
							<FaqItem faq={faq} key={i} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Faq8;
