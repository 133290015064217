import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoEditorForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [coverLetter, setCoverLetter] = useState('');
    const [portfolioLink, setPortfolioLink] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [secretKey, setSecretKey] = useState('');

    useEffect(() => {
        const key = localStorage.getItem('secret_key');
        setSecretKey(key || ''); // Default to empty string if not found
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://profit-backend.onrender.com/api/video-editor/', {
                name,
                email,
                phone,
                cover_letter: coverLetter,
                portfolio_link: portfolioLink,
                secret_key: secretKey,
            });
            setMessage(response.data.success);
            setError('');
        } catch (error) {
            setError(error.response.data.error || 'An error occurred.');
            setMessage('');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen  to-indigo-500">
            <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
            <br/>
            <br/>
            <br/>
            <br/>
                <h2 className="text-3xl font-bold text-center text-[#00416A] mb-6">Apply for Video Editor</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Full Name:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Email Address:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="you@example.com"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number:</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Enter your phone number"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Cover Letter:</label>
                        <textarea
                            value={coverLetter}
                            onChange={(e) => setCoverLetter(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Write a brief cover letter"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Portfolio Link:</label>
                        <input
                            type="url"
                            value={portfolioLink}
                            onChange={(e) => setPortfolioLink(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Paste your portfolio link"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-[#00416A] text-white py-3 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                        Submit Application
                    </button>
                </form>
                {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
                {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
            </div>
        </div>
    );
};

export default VideoEditorForm;
