import React, { useEffect } from 'react';



import Banner01 from '../components/banner/Banner01';
import Crypto01 from '../components/crypto/Crypto01';
import Coinlist01 from '../components/coinlist1/Coinlist';
import Work01 from '../components/work/Work01';
import About01 from '../components/about1/About';
// import Testimonial01 from '../components/testimonial/Testimonial01';
// import Sale01 from '../components/sale/Sale01';
import dataPartner from '../assets/fake-data/data-partner';
import dataTestimonial from '../assets/fake-data/data-testimonial';
import dataWork from '../assets/fake-data/data-work';
import dataCoin from '../assets/fake-data/data-coin';
import Download01 from '../components/download/Download01';
import Services from '../components/service1/Services';
import FAQ from '../components/faq1/FAQ';







function HomeOne(props) {



    return (
        <div className='home-1'>

            <Banner01 data={dataPartner} />

            {/* <Crypto01 /> */}

            <Coinlist01 dataCoin={dataCoin} />

            {/* <Work01 data={dataWork} /> */}

            <About01 />
            
            <Services />

            {/* <Testimonial01 data={dataTestimonial} /> */}

            <FAQ />
            
            
        </div>
    );
}

export default HomeOne;