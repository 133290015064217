// // // import React, { useState, useEffect } from 'react';
// // // import axios from 'axios';
// // // import { useNavigate } from 'react-router-dom';

// // // const ProfileForm = () => {
// // //   const [personalInfo, setPersonalInfo] = useState({
// // //     firstName: '',
// // //     lastName: '',
// // //     email: '',
// // //     birthDate: '',
// // //     nationality: '',
// // //     gender: '',
// // //     phone: '',
// // //     address: {
// // //       street: '',
// // //       city: '',
// // //       state: '',
// // //       country: '',
// // //       zipCode: '',
// // //     }
// // //   });

// // //   const [education, setEducation] = useState([
// // //     { institution: '', degree: '', fieldOfStudy: '', startDate: '', endDate: '', grade: '' }
// // //   ]);

// // //   const [workExperience, setWorkExperience] = useState([
// // //     { company: '', jobTitle: '', startDate: '', endDate: '', responsibilities: '' }
// // //   ]);

// // //   const [isEditing, setIsEditing] = useState(false);
// // //   const navigate = useNavigate();

// // //   useEffect(() => {
// // //     const fetchProfile = async () => {
// // //       try {
// // //         const response = await axios.get('http://localhost:8000/api/profile/');
// // //         const data = response.data;

// // //         setPersonalInfo({
// // //           firstName: data.first_name,
// // //           lastName: data.last_name,
// // //           email: data.email,
// // //           birthDate: data.birth_date,
// // //           nationality: data.nationality,
// // //           gender: data.gender,
// // //           phone: data.phone,
// // //           address: {
// // //             street: data.address.street,
// // //             city: data.address.city,
// // //             state: data.address.state,
// // //             country: data.address.country,
// // //             zipCode: data.address.zip_code,
// // //           }
// // //         });

// // //         setEducation(data.education.map(edu => ({
// // //           institution: edu.institution,
// // //           degree: edu.degree,
// // //           fieldOfStudy: edu.field_of_study,
// // //           startDate: edu.start_date,
// // //           endDate: edu.end_date,
// // //           grade: edu.grade,
// // //         })));

// // //         setWorkExperience(data.work_experience.map(work => ({
// // //           company: work.company,
// // //           jobTitle: work.job_title,
// // //           startDate: work.start_date,
// // //           endDate: work.end_date,
// // //           responsibilities: work.responsibilities,
// // //         })));
// // //       } catch (error) {
// // //         console.error('There was an error fetching the profile!', error);
// // //         navigate('/login');
// // //       }
// // //     };

// // //     fetchProfile();
// // //   }, [navigate]);

// // //   const handlePersonalInfoChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setPersonalInfo(prevState => ({
// // //       ...prevState,
// // //       [name]: value,
// // //     }));
// // //   };

// // //   const handleAddressChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setPersonalInfo(prevState => ({
// // //       ...prevState,
// // //       address: {
// // //         ...prevState.address,
// // //         [name]: value
// // //       }
// // //     }));
// // //   };

// // //   const handleEducationChange = (index, e) => {
// // //     const { name, value } = e.target;
// // //     const newEducation = [...education];
// // //     newEducation[index][name] = value;
// // //     setEducation(newEducation);
// // //   };

// // //   const handleWorkExperienceChange = (index, e) => {
// // //     const { name, value } = e.target;
// // //     const newWorkExperience = [...workExperience];
// // //     newWorkExperience[index][name] = value;
// // //     setWorkExperience(newWorkExperience);
// // //   };

// // //   const addEducation = () => {
// // //     setEducation([...education, { institution: '', degree: '', fieldOfStudy: '', startDate: '', endDate: '', grade: '' }]);
// // //   };

// // //   const addWorkExperience = () => {
// // //     setWorkExperience([...workExperience, { company: '', jobTitle: '', startDate: '', endDate: '', responsibilities: '' }]);
// // //   };

// // //   const handleSubmit = async (e) => {
// // //     e.preventDefault();
// // //     const data = {
// // //       first_name: personalInfo.firstName,
// // //       last_name: personalInfo.lastName,
// // //       email: personalInfo.email,
// // //       birth_date: personalInfo.birthDate,
// // //       nationality: personalInfo.nationality,
// // //       gender: personalInfo.gender,
// // //       phone: personalInfo.phone,
// // //       address: {
// // //         street: personalInfo.address.street,
// // //         city: personalInfo.address.city,
// // //         state: personalInfo.address.state,
// // //         country: personalInfo.address.country,
// // //         zip_code: personalInfo.address.zipCode,
// // //       },
// // //       education: education.map(edu => ({
// // //         institution: edu.institution,
// // //         degree: edu.degree,
// // //         field_of_study: edu.fieldOfStudy,
// // //         start_date: edu.startDate,
// // //         end_date: edu.endDate,
// // //         grade: edu.grade,
// // //       })),
// // //       work_experience: workExperience.map(work => ({
// // //         company: work.company,
// // //         job_title: work.jobTitle,
// // //         start_date: work.startDate,
// // //         end_date: work.endDate,
// // //         responsibilities: work.responsibilities,
// // //       })),
// // //     };

// // //     try {
// // //       const response = await axios.put('http://localhost:8000/api/profile/', data, {
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //         }
// // //       });
// // //       console.log('Profile updated:', response.data);
// // //       setIsEditing(false); // Switch to view mode after submission
// // //     } catch (error) {
// // //       console.error('There was an error!', error);
// // //     }
// // //   };

// // //   return (
// // //     <div>
// // //       {isEditing ? (
// // //         <form onSubmit={handleSubmit}>
// // //           <h2>Personal Information</h2>
// // //           <label>First Name:</label>
// // //           <input type="text" name="firstName" value={personalInfo.firstName} onChange={handlePersonalInfoChange} required />
// // //           <label>Last Name:</label>
// // //           <input type="text" name="lastName" value={personalInfo.lastName} onChange={handlePersonalInfoChange} required />
// // //           <label>Email:</label>
// // //           <input type="email" name="email" value={personalInfo.email} onChange={handlePersonalInfoChange} required />
// // //           <label>Birth Date:</label>
// // //           <input type="date" name="birthDate" value={personalInfo.birthDate} onChange={handlePersonalInfoChange} />
// // //           <label>Nationality:</label>
// // //           <input type="text" name="nationality" value={personalInfo.nationality} onChange={handlePersonalInfoChange} />
// // //           <label>Gender:</label>
// // //           <select name="gender" value={personalInfo.gender} onChange={handlePersonalInfoChange}>
// // //             <option value="Male">Male</option>
// // //             <option value="Female">Female</option>
// // //             <option value="Non-Binary">Non-Binary</option>
// // //             <option value="Prefer not to say">Prefer not to say</option>
// // //           </select>
// // //           <label>Phone:</label>
// // //           <input type="tel" name="phone" value={personalInfo.phone} onChange={handlePersonalInfoChange} />
// // //           <h3>Address</h3>
// // //           <label>Street:</label>
// // //           <input type="text" name="street" value={personalInfo.address.street} onChange={handleAddressChange} />
// // //           <label>City:</label>
// // //           <input type="text" name="city" value={personalInfo.address.city} onChange={handleAddressChange} />
// // //           <label>State:</label>
// // //           <input type="text" name="state" value={personalInfo.address.state} onChange={handleAddressChange} />
// // //           <label>Country:</label>
// // //           <input type="text" name="country" value={personalInfo.address.country} onChange={handleAddressChange} />
// // //           <label>Zip Code:</label>
// // //           <input type="text" name="zipCode" value={personalInfo.address.zipCode} onChange={handleAddressChange} />

// // //           <h2>Education</h2>
// // //           {education.map((edu, index) => (
// // //             <div key={index}>
// // //               <label>Institution:</label>
// // //               <input type="text" name="institution" value={edu.institution} onChange={e => handleEducationChange(index, e)} required />
// // //               <label>Degree:</label>
// // //               <input type="text" name="degree" value={edu.degree} onChange={e => handleEducationChange(index, e)} required />
// // //               <label>Field of Study:</label>
// // //               <input type="text" name="fieldOfStudy" value={edu.fieldOfStudy} onChange={e => handleEducationChange(index, e)} required />
// // //               <label>Start Date:</label>
// // //               <input type="date" name="startDate" value={edu.startDate} onChange={e => handleEducationChange(index, e)} required />
// // //               <label>End Date:</label>
// // //               <input type="date" name="endDate" value={edu.endDate} onChange={e => handleEducationChange(index, e)} />
// // //               <label>Grade:</label>
// // //               <input type="text" name="grade" value={edu.grade} onChange={e => handleEducationChange(index, e)} />
// // //             </div>
// // //           ))}
// // //           <button type="button" onClick={addEducation}>Add Education</button>

// // //           <h2>Work Experience</h2>
// // //           {workExperience.map((work, index) => (
// // //             <div key={index}>
// // //               <label>Company:</label>
// // //               <input type="text" name="company" value={work.company} onChange={e => handleWorkExperienceChange(index, e)} required />
// // //               <label>Job Title:</label>
// // //               <input type="text" name="jobTitle" value={work.jobTitle} onChange={e => handleWorkExperienceChange(index, e)} required />
// // //               <label>Start Date:</label>
// // //               <input type="date" name="startDate" value={work.startDate} onChange={e => handleWorkExperienceChange(index, e)} required />
// // //               <label>End Date:</label>
// // //               <input type="date" name="endDate" value={work.endDate} onChange={e => handleWorkExperienceChange(index, e)} />
// // //               <label>Responsibilities:</label>
// // //               <textarea name="responsibilities" value={work.responsibilities} onChange={e => handleWorkExperienceChange(index, e)} />
// // //             </div>
// // //           ))}
// // //           <button type="button" onClick={addWorkExperience}>Add Work Experience</button>

// // //           <button type="submit">Save</button>
// // //         </form>
// // //       ) : (
// // //         <div>
// // //           <h2>Personal Information</h2>
// // //           <p>Name: {personalInfo.firstName} {personalInfo.lastName}</p>
// // //           <p>Email: {personalInfo.email}</p>
// // //           <p>Birth Date: {personalInfo.birthDate}</p>
// // //           <p>Nationality: {personalInfo.nationality}</p>
// // //           <p>Gender: {personalInfo.gender}</p>
// // //           <p>Phone: {personalInfo.phone}</p>
// // //           <h3>Address</h3>
// // //           <p>Street: {personalInfo.address.street}</p>
// // //           <p>City: {personalInfo.address.city}</p>
// // //           <p>State: {personalInfo.address.state}</p>
// // //           <p>Country: {personalInfo.address.country}</p>
// // //           <p>Zip Code: {personalInfo.address.zipCode}</p>

// // //           <h2>Education</h2>
// // //           {education.map((edu, index) => (
// // //             <div key={index}>
// // //               <p>Institution: {edu.institution}</p>
// // //               <p>Degree: {edu.degree}</p>
// // //               <p>Field of Study: {edu.fieldOfStudy}</p>
// // //               <p>Start Date: {edu.startDate}</p>
// // //               <p>End Date: {edu.endDate}</p>
// // //               <p>Grade: {edu.grade}</p>
// // //             </div>
// // //           ))}

// // //           <h2>Work Experience</h2>
// // //           {workExperience.map((work, index) => (
// // //             <div key={index}>
// // //               <p>Company: {work.company}</p>
// // //               <p>Job Title: {work.jobTitle}</p>
// // //               <p>Start Date: {work.startDate}</p>
// // //               <p>End Date: {work.endDate}</p>
// // //               <p>Responsibilities: {work.responsibilities}</p>
// // //             </div>
// // //           ))}
// // //           <button onClick={() => setIsEditing(true)}>Edit</button>
// // //         </div>
// // //       )}
// // //     </div>
// // //   );
// // // };

// // // export default ProfileForm;
// // import React from 'react';
// // import { useForm, useFieldArray, Controller } from 'react-hook-form';
// // import axios from 'axios';

// // const ProfileFormComponent = () => {
// //   const { control, handleSubmit, register, reset, setValue } = useForm({
// //     defaultValues: {
// //       first_name: '',
// //       last_name: '',
// //       email: '',
// //       birth_date: '',
// //       nationality: '',
// //       gender: '',
// //       phone: '',
// //       street: '',
// //       city: '',
// //       state: '',
// //       country: '',
// //       zip_code: '',
// //       educations: [],
// //       work_experiences: []
// //     }
// //   });

// //   const { fields: educationFields, append: appendEducation, remove: removeEducation } = useFieldArray({
// //     control,
// //     name: 'educations'
// //   });

// //   const { fields: workExperienceFields, append: appendWorkExperience, remove: removeWorkExperience } = useFieldArray({
// //     control,
// //     name: 'work_experiences'
// //   });

// //   const onSubmit = async (data) => {
// //     try {
// //       await axios.post('http://localhost:8000/api/profile/', data);
// //       alert('Profile saved successfully!');
// //       reset();
// //     } catch (error) {
// //       console.error('Error saving profile:', error);
// //     }
// //   };

// //   return (
// //     <form onSubmit={handleSubmit(onSubmit)}>
// //       {/* Personal Information Section */}
// //       <section>
// //         <h2>Personal Information</h2>
// //         <div>
// //           <label>First Name</label>
// //           <input {...register('first_name')} />
// //         </div>
// //         <div>
// //           <label>Last Name</label>
// //           <input {...register('last_name')} />
// //         </div>
// //         <div>
// //           <label>Email</label>
// //           <input type="email" {...register('email')} />
// //         </div>
// //         <div>
// //           <label>Birth Date</label>
// //           <input type="date" {...register('birth_date')} />
// //         </div>
// //         <div>
// //           <label>Nationality</label>
// //           <input {...register('nationality')} />
// //         </div>
// //         <div>
// //           <label>Gender</label>
// //           <select {...register('gender')}>
// //             <option value="Male">Male</option>
// //             <option value="Female">Female</option>
// //             <option value="Non-Binary">Non-Binary</option>
// //             <option value="Prefer not to say">Prefer not to say</option>
// //           </select>
// //         </div>
// //         <div>
// //           <label>Phone</label>
// //           <input {...register('phone')} />
// //         </div>
// //         <div>
// //           <label>Street</label>
// //           <input {...register('street')} />
// //         </div>
// //         <div>
// //           <label>City</label>
// //           <input {...register('city')} />
// //         </div>
// //         <div>
// //           <label>State</label>
// //           <input {...register('state')} />
// //         </div>
// //         <div>
// //           <label>Country</label>
// //           <input {...register('country')} />
// //         </div>
// //         <div>
// //           <label>Zip Code</label>
// //           <input {...register('zip_code')} />
// //         </div>
// //       </section>

// //       {/* Education Section */}
// //       <section>
// //         <h2>Education</h2>
// //         {educationFields.map((item, index) => (
// //           <div key={item.id}>
// //             <h3>Education {index + 1}</h3>
// //             <button type="button" onClick={() => removeEducation(index)}>Remove</button>
// //             <div>
// //               <label>Institution</label>
// //               <Controller
// //                 name={`educations[${index}].institution`}
// //                 control={control}
// //                 render={({ field }) => <input {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Degree</label>
// //               <Controller
// //                 name={`educations[${index}].degree`}
// //                 control={control}
// //                 render={({ field }) => <input {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Field of Study</label>
// //               <Controller
// //                 name={`educations[${index}].field_of_study`}
// //                 control={control}
// //                 render={({ field }) => <input {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Start Date</label>
// //               <Controller
// //                 name={`educations[${index}].start_date`}
// //                 control={control}
// //                 render={({ field }) => <input type="date" {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>End Date</label>
// //               <Controller
// //                 name={`educations[${index}].end_date`}
// //                 control={control}
// //                 render={({ field }) => <input type="date" {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Grade</label>
// //               <Controller
// //                 name={`educations[${index}].grade`}
// //                 control={control}
// //                 render={({ field }) => <input {...field} />}
// //               />
// //             </div>
// //           </div>
// //         ))}
// //         <button type="button" onClick={() => appendEducation({ institution: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' })}>
// //           Add Education
// //         </button>
// //       </section>

// //       {/* Work Experience Section */}
// //       <section>
// //         <h2>Work Experience</h2>
// //         {workExperienceFields.map((item, index) => (
// //           <div key={item.id}>
// //             <h3>Work Experience {index + 1}</h3>
// //             <button type="button" onClick={() => removeWorkExperience(index)}>Remove</button>
// //             <div>
// //               <label>Company</label>
// //               <Controller
// //                 name={`work_experiences[${index}].company`}
// //                 control={control}
// //                 render={({ field }) => <input {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Job Title</label>
// //               <Controller
// //                 name={`work_experiences[${index}].job_title`}
// //                 control={control}
// //                 render={({ field }) => <input {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Start Date</label>
// //               <Controller
// //                 name={`work_experiences[${index}].start_date`}
// //                 control={control}
// //                 render={({ field }) => <input type="date" {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>End Date</label>
// //               <Controller
// //                 name={`work_experiences[${index}].end_date`}
// //                 control={control}
// //                 render={({ field }) => <input type="date" {...field} />}
// //               />
// //             </div>
// //             <div>
// //               <label>Responsibilities</label>
// //               <Controller
// //                 name={`work_experiences[${index}].responsibilities`}
// //                 control={control}
// //                 render={({ field }) => <textarea {...field} />}
// //               />
// //             </div>
// //           </div>
// //         ))}
// //         <button type="button" onClick={() => appendWorkExperience({ company: '', job_title: '', start_date: '', end_date: '', responsibilities: '' })}>
// //           Add Work Experience
// //         </button>
// //       </section>

// //       <button type="submit">Save Profile</button>
// //     </form>
// //   );
// // };

// // export default ProfileFormComponent;


// // import React, { useState, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';

// // const UserProfile = () => {
// //   const [userData, setUserData] = useState(null); // State to hold user data
// //   const [loading, setLoading] = useState(true); // State to handle loading state
// //   const [error, setError] = useState(null); // State to handle errors
// //   const navigate = useNavigate();

// //   // Function to fetch user data from the backend
// //   const fetchUserData = async () => {
// //     const secretKey = localStorage.getItem('secret_key');
// //     const token = localStorage.getItem('accessToken');

// //     // Redirect to login if no secret_key or token is found
// //     if (!secretKey || !token) {
// //       navigate('/login');
// //       return;
// //     }

// //     try {
// //       const response = await fetch('http://localhost:8000/api/user-data/', {
// //         headers: {
// //           'Authorization': `Bearer ${token}`,
// //           'X-Secret-Key': secretKey,
// //         },
// //       });

// //       if (response.status === 401) {
// //         // Handle unauthorized access - token may have expired
// //         console.error('Unauthorized - possible expired token.');
// //         navigate('/login'); // Redirect to login
// //         return;
// //       }

// //       if (!response.ok) {
// //         throw new Error('Failed to fetch user data');
// //       }

// //       const data = await response.json();
// //       setUserData(data);
// //     } catch (error) {
// //       console.error('Error fetching user data:', error);
// //       setError(error.message);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   // Use Effect hook to fetch user data on component mount
// //   useEffect(() => {
// //     fetchUserData();
// //   }, []);

// //   // Function to log out the user
// //   const handleLogout = () => {
// //     localStorage.removeItem('secret_key');
// //     localStorage.removeItem('accessToken');
// //     navigate('/login');
// //   };

// //   // Render loading state
// //   if (loading) {
// //     return <div>Loading...</div>;
// //   }

// //   // Render error state
// //   if (error) {
// //     return <div>Error: {error}</div>;
// //   }

// //   // Render user profile information
// //   return (
// //     <div className="profile-container">
// //       <br/>
// //       <br/>
// //       <br/>
// //       <h1>User Profile</h1>
// //       {userData ? (
// //         <div className="profile-details">
// //           <p><strong>Name:</strong> {userData.first_name}</p>
// //           <p><strong>Email:</strong> {userData.email}</p>
// //           {/* Display additional user information as needed */}
// //           <p><strong>Joined On:</strong> {new Date(userData.joined_on).toLocaleDateString()}</p>
// //           {/* Add more fields as needed */}
// //           <button onClick={handleLogout}>Logout</button>
// //         </div>
// //       ) : (
// //         <p>No user data found. Please log in again.</p>
// //       )}
// //     </div>
// //   );
// // };

// // export default UserProfile;


// // import React, { useState, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';

// // const UserProfile = () => {
// //   const [userData, setUserData] = useState(null); // State to hold user data
// //   const [loading, setLoading] = useState(true); // State to handle loading state
// //   const [error, setError] = useState(null); // State to handle errors
// //   const navigate = useNavigate();

// //   // Function to fetch user data from the backend
// //   const fetchUserData = async () => {
// //     const secretKey = localStorage.getItem('secret_key');
// //     const token = localStorage.getItem('accessToken');

// //     // Redirect to login if no secret_key or token is found
// //     if (!secretKey || !token) {
// //       navigate('/login');
// //       return;
// //     }

// //     try {
// //       const response = await fetch('http://localhost:8000/api/user-data/', {
// //         headers: {
// //           Authorization: `Bearer ${token}`,
// //           'X-Secret-Key': secretKey,
// //         },
// //       });

// //       if (response.status === 401) {
// //         // Handle unauthorized access - token may have expired
// //         console.error('Unauthorized - possible expired token.');
// //         navigate('/login'); // Redirect to login
// //         return;
// //       }

// //       if (!response.ok) {
// //         throw new Error('Failed to fetch user data');
// //       }

// //       const data = await response.json();
// //       setUserData(data);
// //     } catch (error) {
// //       console.error('Error fetching user data:', error);
// //       setError(error.message);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   // Use Effect hook to fetch user data on component mount
// //   useEffect(() => {
// //     fetchUserData();
// //   }, []);

// //   // Function to log out the user
// //   const handleLogout = () => {
// //     localStorage.removeItem('secret_key');
// //     localStorage.removeItem('accessToken');
// //     navigate('/login');
// //   };

// //   // Function to handle profile update
// //   const handleUpdate = () => {
// //     // Logic for updating user profile information
// //     console.log('Profile updated successfully.');
// //   };

// //   // Render loading state
// //   if (loading) {
// //     return <div>Loading...</div>;
// //   }

// //   // Render error state
// //   if (error) {
// //     return <div>Error: {error}</div>;
// //   }

// //   // Render user profile information
// //   return (
// //     <div className="max-w-4xl mx-auto mt-16 mb-20 p-8 border border-gray-200 rounded-lg shadow-lg dark:bg-gray-900">
// //       <h2 className="text-3xl font-bold text-center dark:text-gray-200">User Profile</h2>

// //       {userData ? (
// //         <form className="mt-8">
// //           {/* First Name */}
// //           <div className="mt-4">
// //             <label className="block text-gray-600 dark:text-gray-300 mb-2">First Name</label>
// //             <input
// //               type="text"
// //               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
// //               value={userData.first_name || ''}
// //               onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
// //             />
// //           </div>

// //           {/* Last Name */}
// //           <div className="mt-4">
// //             <label className="block text-gray-600 dark:text-gray-300 mb-2">Last Name</label>
// //             <input
// //               type="text"
// //               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
// //               value={userData.last_name || ''}
// //               onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
// //             />
// //           </div>

// //           {/* Email */}
// //           <div className="mt-4">
// //             <label className="block text-gray-600 dark:text-gray-300 mb-2">Email</label>
// //             <input
// //               type="email"
// //               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
// //               value={userData.email || ''}
// //               onChange={(e) => setUserData({ ...userData, email: e.target.value })}
// //             />
// //           </div>

// //           {/* Phone */}
// //           <div className="mt-4">
// //             <label className="block text-gray-600 dark:text-gray-300 mb-2">Phone</label>
// //             <input
// //               type="text"
// //               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
// //               value={userData.phone || ''}
// //               onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
// //             />
// //           </div>

// //           {/* Nationality */}
// //           <div className="mt-4">
// //             <label className="block text-gray-600 dark:text-gray-300 mb-2">Nationality</label>
// //             <select
// //               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
// //               value={userData.nationality || ''}
// //               onChange={(e) => setUserData({ ...userData, nationality: e.target.value })}
// //             >
// //               <option disabled>Select Nationality</option>
// //               <option value="USA">USA</option>
// //               <option value="Canada">Canada</option>
// //               <option value="UK">UK</option>
// //               {/* Add more nationalities as needed */}
// //             </select>
// //           </div>

// //           {/* Update Button */}
// //           <div className="w-full flex justify-center mt-6">
// //             <button
// //               type="button"
// //               className="rounded-lg text-white bg-blue-700 text-lg px-10 py-2"
// //               onClick={handleUpdate}
// //             >
// //               Update
// //             </button>
// //           </div>

// //           {/* Logout Button */}
// //           <div className="w-full flex justify-center mt-6">
// //             <button
// //               type="button"
// //               className="rounded-lg text-white bg-red-700 text-lg px-10 py-2"
// //               onClick={handleLogout}
// //             >
// //               Logout
// //             </button>
// //           </div>
// //         </form>
// //       ) : (
// //         <p className="mt-4 text-center dark:text-gray-200">No user data found. Please log in again.</p>
// //       )}
// //     </div>
// //   );
// // };

// // export default UserProfile;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const UserProfile = () => {
//   const [userData, setUserData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const fetchUserData = async () => {
//     const secretKey = localStorage.getItem('secret_key');
//     const token = localStorage.getItem('accessToken');

//     if (!secretKey || !token) {
//       navigate('/login');
//       return;
//     }

//     try {
//       const response = await fetch('https://profit-backend.onrender.com/api/user-data/', {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'X-Secret-Key': secretKey,
//         },
//       });

//       if (response.status === 401) {
//         console.error('Unauthorized - possible expired token.');
//         navigate('/login');
//         return;
//       }

//       if (!response.ok) {
//         throw new Error('Failed to fetch user data');
//       }

//       const data = await response.json();
//       setUserData(data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   // Function to handle profile update
//   const handleUpdate = async () => {
//     const token = localStorage.getItem('accessToken');
//     const secretKey = localStorage.getItem('secret_key');
  
//     if (!token || !secretKey) {
//       console.error('Missing authentication details.');
//       return;
//     }
  
//     try {
//       const response = await fetch('https://profit-backend.onrender.com/api/user-data/', {
//         method: 'PUT', // Changed to PUT method
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//           'X-Secret-Key': secretKey,
//         },
//         body: JSON.stringify(userData), // Ensure all fields are included
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to update user data');
//       }
  
//       console.log('Profile updated successfully.');
//       alert('Profile updated successfully.');
//     } catch (error) {
//       console.error('Error updating user data:', error);
//       alert('Failed to update profile.');
//     }
//   };

//   // Function to log out the user
//   const handleLogout = () => {
//     // Clear local storage and redirect to login
//     localStorage.removeItem('secret_key');
//     localStorage.removeItem('accessToken');
//     navigate('/login');
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="max-w-4xl mx-auto mt-16 mb-20 p-8 border border-gray-200 rounded-lg shadow-lg dark:bg-gray-900">
//       <h2 className="text-3xl font-bold text-center dark:text-gray-200">User Profile</h2>

//       {userData ? (
//         <form className="mt-8 space-y-6">
//           {[
//             { label: 'First Name', type: 'text', field: 'first_name' },
//             { label: 'Last Name', type: 'text', field: 'last_name' },
//             { label: 'Email', type: 'email', field: 'email' },
//             { label: 'Phone', type: 'text', field: 'phone' },
//             { label: 'Country', type: 'text', field: 'nationality' },
//           ].map((input) => (
//             <div className="mt-4" key={input.field}>
//               <label className="block text-gray-600 dark:text-gray-300 mb-2">{input.label}</label>
//               <input
//                 type={input.type}
//                 className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//                 value={userData[input.field] || ''}
//                 onChange={(e) => setUserData({ ...userData, [input.field]: e.target.value })}
//               />
//             </div>
//           ))}

//           {/* <div className="mt-4">
//             <label className="block text-gray-600 dark:text-gray-300 mb-2">Nationality</label>
//             <select
//               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//               value={userData.nationality || ''}
//               onChange={(e) => setUserData({ ...userData, nationality: e.target.value })}
//             >
//               <option disabled>Select Nationality</option>
//               <option value="USA">USA</option>
//               <option value="Canada">Canada</option>
//               <option value="UK">UK</option>
//             </select>
//           </div> */}

//           <div className="w-full flex justify-center mt-6 space-x-4">
//             <button
//               type="button"
//               className="rounded-lg text-white bg-blue-700 text-lg px-10 py-2"
//               onClick={handleUpdate}
//             >
//               Update
//             </button>
//             <button
//               type="button"
//               className="rounded-lg text-white bg-red-700 text-lg px-10 py-2"
//               onClick={handleLogout}
//             >
//               Logout
//             </button>
//           </div>
//         </form>
//       ) : (
//         <p className="mt-4 text-center dark:text-gray-200">No user data found. Please log in again.</p>
//       )}
//     </div>
//   );
// };

// export default UserProfile;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use `useNavigate` instead of `useHistory`
import axios from 'axios';

const ProfilePage = () => {
    const [profile, setProfile] = useState({});
    const [education, setEducation] = useState([]);
    const [experience, setExperience] = useState([]);
    const [error, setError] = useState('');
    const [secretKey, setSecretKey] = useState('');

    const navigate = useNavigate(); // Hook to handle redirection

    useEffect(() => {
        const key = localStorage.getItem('secret_key');
        setSecretKey(key || '');
        fetchProfileData(key);
    }, []);

    const fetchProfileData = async (key) => {
        try {
            const response = await axios.get(`https://profit-backend.onrender.com/api/profile/?secret_key=${key}`);
            const userData = response.data.user;
            setProfile(userData);
            setEducation(response.data.education);
            setExperience(response.data.experience);

            // Update localStorage with the fetched username
            localStorage.setItem('username', userData.username);
        } catch (error) {
            setError('Error fetching profile data.');
        }
    };

    // Function to handle the redirection to the edit profile page
    const handleEditRedirect = () => {
        navigate('/profile'); // Redirect to the Edit Profile page
    };

    // Function to handle redirection to the Add Education page
    const handleAddEducation = () => {
        navigate('/education'); // Redirect to Add Education page
    };

    // Function to handle redirection to the Add Experience page
    const handleAddExperience = () => {
        navigate('/add-experience'); // Redirect to Add Experience page
    };

    return (
        <div className="flex justify-center items-center min-h-screen mt-20 inset-0 z-50 overflow-y-auto">
            <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
                <h2 className="text-2xl font-medium leading-normal text-gray-800 text-center">Profile</h2>

                <div className="space-y-6 mt-6">
                    <div>
                        <h3 className="text-xl font-medium text-gray-800">Personal Information</h3>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Username:</label>
                            <input
                                type="text"
                                name="username"
                                value={profile.username || ''}
                                disabled
                                className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Birth Date:</label>
                            <input
                                type="date"
                                name="birth_date"
                                value={profile.birth_date || ''}
                                disabled
                                className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Country:</label>
                            <input
                                type="text"
                                name="nationality"
                                value={profile.nationality || ''}
                                disabled
                                className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                            />
                        </div>
                        {/* <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Gender:</label>
                            <input
                                type="text"
                                name="gender"
                                value={profile.gender || ''}
                                disabled
                                className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                            />
                        </div> */}
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Phone:</label>
                            <input
                                type="text"
                                name="phone"
                                value={profile.phone || ''}
                                disabled
                                className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                            />
                        </div>
                    </div>

                    <div>
                        <h3 className="text-xl font-medium text-gray-800">Education</h3>
                        {education.length > 0 ? (
                            education.map((record, index) => (
                                <div key={index} className="mt-4 border-t pt-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Degree:</label>
                                        <input
                                            type="text"
                                            name="degree"
                                            value={record.degree || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Field of Study:</label>
                                        <input
                                            type="text"
                                            name="fieldofstudy"
                                            value={record.fieldofstudy || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Institute:</label>
                                        <input
                                            type="text"
                                            name="institution"
                                            value={record.institution || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Start Date:</label>
                                        <input
                                            type="text"
                                            name="startdate"
                                            value={record.startdate || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">End Date:</label>
                                        <input
                                            type="text"
                                            name="enddate"
                                            value={record.enddate || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Grade:</label>
                                        <input
                                            type="text"
                                            name="grade"
                                            value={record.grade || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                </div>
                                
                            ))
                        ) : (
                            <p className="text-sm text-gray-500">No education records found.</p>
                        )}
                        <button
                            onClick={handleAddEducation}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full"
                        >
                            Add Education
                        </button>
                    </div>

                    <div>
                        <h3 className="text-xl font-medium text-gray-800">Experience</h3>
                        {experience.length > 0 ? (
                            experience.map((record, index) => (
                                <div key={index} className="mt-4 border-t pt-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Job Title:</label>
                                        <input
                                            type="text"
                                            name="jobtitle"
                                            value={record.jobtitle || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Company:</label>
                                        <input
                                            type="text"
                                            name="company"
                                            value={record.company || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Start Date:</label>
                                        <input
                                            type="text"
                                            name="startdate"
                                            value={record.startdate || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">End Date:</label>
                                        <input
                                            type="text"
                                            name="enddate"
                                            value={record.enddate || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Responsibilities:</label>
                                        <input
                                            type="text"
                                            name="responsibilities"
                                            value={record.responsibilities || ''}
                                            disabled
                                            className="bg-transparent border-b border-black focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-sm text-gray-500">No experience records found.</p>
                        )}
                        <button
                            onClick={handleAddExperience}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full"
                        >
                            Add Experience
                        </button>
                    </div>

                    {/* <div className="flex justify-center mt-6">
                        <button
                            type="button"
                            onClick={handleEditRedirect}
                            className="bg-gray-500 text-white px-4 py-2 rounded-full"
                        >
                            Edit Profile
                        </button>
                    </div> */}
                </div>

                {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
            </div>
        </div>
    );
};

export default ProfilePage;


