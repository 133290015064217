import React, { useState } from 'react';
import axios from 'axios';

const JobApplicationForm = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        birth_date: '0',
        nationality: '',
        gender: '',
        street: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        referal_sourse: '',
        ethnicity: '',
        Religion: '',
        current_employment: '',
        linkdein_profile: '',
        remote_experience: '',
        previous_international: false,
        availibillity: false,
        refrence: '',
        expected_salary_local: '',
        expected_salary_gbp: '',
        visa_requirement: false,
        educations: [{
            institution: '',
            degree: '',
            fieldofstudy: '',
            startdate: '',
            enddate: ''
        }],
        work_experiences: [{
            company: '',
            jobtitle: '',
            startdate: '',
            enddate: '',
            responsibilities: ''
        }]
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleEducationChange = (index, field, value) => {
        const updatedEducations = formData.educations.map((edu, i) =>
            i === index ? { ...edu, [field]: value } : edu
        );
        setFormData(prevState => ({
            ...prevState,
            educations: updatedEducations
        }));
    };

    const handleExperienceChange = (index, field, value) => {
        const updatedExperiences = formData.work_experiences.map((exp, i) =>
            i === index ? { ...exp, [field]: value } : exp
        );
        setFormData(prevState => ({
            ...prevState,
            work_experiences: updatedExperiences
        }));
    };

    const handleAddEducation = () => {
        setFormData(prevState => ({
            ...prevState,
            educations: [
                ...prevState.educations,
                {
                    institution: '',
                    degree: '',
                    fieldofstudy: '',
                    startdate: '',
                    enddate: ''
                }
            ]
        }));
    };

    const handleAddExperience = () => {
        setFormData(prevState => ({
            ...prevState,
            work_experiences: [
                ...prevState.work_experiences,
                {
                    company: '',
                    jobtitle: '',
                    startdate: '',
                    enddate: '',
                    responsibilities: ''
                }
            ]
        }));
    };

    const handleRemoveEducation = (index) => {
        const updatedEducations = formData.educations.filter((_, i) => i !== index);
        setFormData(prevState => ({
            ...prevState,
            educations: updatedEducations
        }));
    };

    const handleRemoveExperience = (index) => {
        const updatedExperiences = formData.work_experiences.filter((_, i) => i !== index);
        setFormData(prevState => ({
            ...prevState,
            work_experiences: updatedExperiences
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Submit the form data
            const response = await axios.post('https://profit-backend.onrender.com/api/hiring-form/', formData, {
                headers: {
                    'Content-Type': 'Application/json'
                }
            });
            console.log('Response:', response.data);
            // Handle success
        } catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            // Handle error
        }
    };

    return (
        <div className="p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-6">Job Application Form</h2>
            <div className="bg-[#F5F5F5] p-6 rounded-lg">
                <form onSubmit={handleSubmit}>
                    {/* Personal Information Section */}
                    <h3 className="text-xl font-bold mb-6 text-[#07074D]">Personal Information</h3>
                    {[
                        { id: 'first_name', label: 'First Name', type: 'text' },
                        { id: 'last_name', label: 'Last Name', type: 'text' },
                        { id: 'email', label: 'Email', type: 'email' },
                        { id: 'phone', label: 'Phone', type: 'text' },
                        { id: 'bith_date', label: 'Birth Date', type: 'text' },
                        { id: 'nationality', label: 'Nationality', type: 'text' },
                        { id: 'street', label: 'Street', type: 'text' },
                        { id: 'city', label: 'City', type: 'text' },
                        { id: 'state', label: 'State', type: 'text' },
                        { id: 'country', label: 'Country', type: 'text' },
                        { id: 'zip_code', label: 'Zip Code', type: 'text' },
                        { id: 'referal_sourse', label: 'Referral Source', type: 'text' },
                        { id: 'ethnicity', label: 'Ethnicity', type: 'text' },
                        { id: 'Religion', label: 'Religion', type: 'text' },
                        { id: 'current_employment', label: 'Current Employment', type: 'text' },
                        { id: 'linkdein_profile', label: 'LinkedIn Profile', type: 'url' },
                        { id: 'remote_experience', label: 'Remote Experience', type: 'text' },
                        { id: 'refrence', label: 'Refrence', type: 'text' },
                        { id: 'expected_salary_local', label: 'Expected Salary (Local)', type: 'text' },
                        { id: 'expected_salary_gbp', label: 'Expected Salary (GBP)', type: 'text' }
                    ].map(field => (
                        <div key={field.id} className="mb-5">
                            <label htmlFor={field.id} className="mb-3 block text-base font-medium text-[#07074D]">
                                {field.label}
                            </label>
                            <input
                                type={field.type}
                                name={field.id}
                                id={field.id}
                                value={formData[field.id]}
                                onChange={handleInputChange}
                                placeholder={field.label}
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                            />
                        </div>
                    ))}
                    <div className="mb-5">
                        <label htmlFor="gender" className="mb-3 block text-base font-medium text-[#07074D]">
                            Gender
                        </label>
                        <select
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-Binary">Non Binary</option>
                            <option value="Prefer not to say">Prefer not to say</option>
                        </select>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="visa_requirement" className="mb-3 block text-base font-medium text-[#07074D]">
                            Visa Requirement
                        </label>
                        <input
                            type="checkbox"
                            name="visa_requirement"
                            id="visa_requirement"
                            checked={formData.visa_requirement}
                            onChange={handleInputChange}
                            className="w-6 h-6 border border-[#e0e0e0] bg-white outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="previous_international" className="mb-3 block text-base font-medium text-[#07074D]">
                            Previous International Experience
                        </label>
                        <input
                            type="checkbox"
                            name="previous_international"
                            id="previous_international"
                            checked={formData.previous_international}
                            onChange={handleInputChange}
                            className="w-6 h-6 border border-[#e0e0e0] bg-white outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="availabillity" className="mb-3 block text-base font-medium text-[#07074D]">
                            Available to relocate
                        </label>
                        <input
                            type="checkbox"
                            name="availabillity"
                            id="availabillity"
                            checked={formData.availabillity}
                            onChange={handleInputChange}
                            className="w-6 h-6 border border-[#e0e0e0] bg-white outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                    </div>

                    {/* Education Section */}
                    <h3 className="text-xl font-bold mb-6 text-[#00416A]">Education</h3>
                    {formData.educations.map((education, index) => (
                        <div key={index} className="mb-5">
                            <div className="flex justify-between items-center mb-3">
                                <h4 className="text-lg font-semibold">Education {index + 1}</h4>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveEducation(index)}
                                    className="text-red-600"
                                >
                                    Remove
                                </button>
                            </div>
                            {['institution', 'degree', 'fieldofstudy', 'startdate', 'enddate'].map(field => (
                                <div key={field} className="mb-3">
                                    <label htmlFor={`education_${index}_${field}`} className="block text-base font-medium text-[#07074D]">
                                        {field.charAt(0).toUpperCase() + field.slice(1)}
                                    </label>
                                    <input
                                        type="text"
                                        name={`education_${index}_${field}`}
                                        id={`education_${index}_${field}`}
                                        value={education[field]}
                                        onChange={(e) => handleEducationChange(index, field, e.target.value)}
                                        placeholder={field}
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddEducation}
                        className="mb-5 bg-[#00416A] text-white py-2 px-4 rounded-md"
                    >
                        Add Education
                    </button>

                    {/* Work Experience Section */}
                    <h3 className="text-xl font-bold mb-6 text-[#00416A]">Work Experience</h3>
                    {formData.work_experiences.map((experience, index) => (
                        <div key={index} className="mb-5">
                            <div className="flex justify-between items-center mb-3">
                                <h4 className="text-lg font-semibold">Experience {index + 1}</h4>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveExperience(index)}
                                    className="text-red-600"
                                >
                                    Remove
                                </button>
                            </div>
                            {['company', 'jobtitle', 'startdate', 'enddate', 'responsibilities'].map(field => (
                                <div key={field} className="mb-3">
                                    <label htmlFor={`experience_${index}_${field}`} className="block text-base font-medium text-[#07074D]">
                                        {field.charAt(0).toUpperCase() + field.slice(1)}
                                    </label>
                                    <input
                                        type="text"
                                        name={`experience_${index}_${field}`}
                                        id={`experience_${index}_${field}`}
                                        value={experience[field]}
                                        onChange={(e) => handleExperienceChange(index, field, e.target.value)}
                                        placeholder={field}
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddExperience}
                        className="mb-5 bg-[#00416A] text-white py-2 px-4 rounded-md"
                    >
                        Add Work Experience
                    </button>
                    <div>
                    <button
                        type="submit"
                        className="bg-[#00416A] text-white py-2 px-4 rounded-md mt-15"
                    >
                        Submit
                    </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default JobApplicationForm;

// import React, { useState } from 'react';
// import axios from 'axios';

// const JobApplicationForm = () => {
//     const [formData, setFormData] = useState({
//         first_name: '',
//         last_name: '',
//         email: '',
//         phone: '',
//         birth_date: '',
//         nationality: '',
//         gender: '',
//         street: '',
//         city: '',
//         state: '',
//         country: '',
//         zip_code: '',
//         referal_sourse: '',
//         ethnicity: '',
//         Religion: '',
//         current_employment: '',
//         linkdein_profile: '',
//         remote_experience: '',
//         previous_international: false,
//         availibillity: false,
//         refrence: '',
//         expected_salary_local: '',
//         expected_salary_gbp: '',
//         visa_requirement: false,
//         uploaded_file: null,  // Changed from '' to null
//         educations: [{
//             institution: '',
//             degree: '',
//             fieldofstudy: '',
//             startdate: '',
//             enddate: ''
//         }],
//         work_experiences: [{
//             company: '',
//             jobtitle: '',
//             startdate: '',
//             enddate: '',
//             responsibilities: ''
//         }]
//     });

//     const handleInputChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData(prevState => ({
//             ...prevState,
//             [name]: type === 'checkbox' ? checked : value
//         }));
//     };

//     const handleFileChange = (e) => {
//         setFormData(prevState => ({
//             ...prevState,
//             uploaded_file: e.target.files[0]
//         }));
//     };

//     const handleEducationChange = (index, field, value) => {
//         const updatedEducations = formData.educations.map((edu, i) =>
//             i === index ? { ...edu, [field]: value } : edu
//         );
//         setFormData(prevState => ({
//             ...prevState,
//             educations: updatedEducations
//         }));
//     };

//     const handleExperienceChange = (index, field, value) => {
//         const updatedExperiences = formData.work_experiences.map((exp, i) =>
//             i === index ? { ...exp, [field]: value } : exp
//         );
//         setFormData(prevState => ({
//             ...prevState,
//             work_experiences: updatedExperiences
//         }));
//     };

//     const handleAddEducation = () => {
//         setFormData(prevState => ({
//             ...prevState,
//             educations: [
//                 ...prevState.educations,
//                 {
//                     institution: '',
//                     degree: '',
//                     fieldofstudy: '',
//                     startdate: '',
//                     enddate: ''
//                 }
//             ]
//         }));
//     };

//     const handleAddExperience = () => {
//         setFormData(prevState => ({
//             ...prevState,
//             work_experiences: [
//                 ...prevState.work_experiences,
//                 {
//                     company: '',
//                     jobtitle: '',
//                     startdate: '',
//                     enddate: '',
//                     responsibilities: ''
//                 }
//             ]
//         }));
//     };

//     const handleRemoveEducation = (index) => {
//         const updatedEducations = formData.educations.filter((_, i) => i !== index);
//         setFormData(prevState => ({
//             ...prevState,
//             educations: updatedEducations
//         }));
//     };

//     const handleRemoveExperience = (index) => {
//         const updatedExperiences = formData.work_experiences.filter((_, i) => i !== index);
//         setFormData(prevState => ({
//             ...prevState,
//             work_experiences: updatedExperiences
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
    
//         try {
//             const data = new FormData();
    
//             // Append form fields to FormData
//             for (const key in formData) {
//                 if (formData.hasOwnProperty(key)) {
//                     if (key === 'uploaded_file') {
//                         // Append file if it exists
//                         if (formData.uploaded_file && formData.uploaded_file.length > 0) {
//                             data.append('uploaded_file', formData.uploaded_file[0]); // Assuming uploaded_file is a FileList
//                         }
//                     } else {
//                         data.append(key, formData[key]);
//                     }
//                 }
//             }
    
//             // Submit the form data
//             const response = await axios.post('http://127.0.0.1:8000/api/hiring-form/', data, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             });
//             console.log('Response:', response.data);
//             // Handle success
//         } catch (error) {
//             console.error('Error submitting form:', error.response ? error.response.data : error.message);
//             // Handle error
//         }
//     };

//     return (
//         <div className="p-6 bg-white shadow-md rounded-lg">
//             <h2 className="text-2xl font-bold mb-6">Job Application Form</h2>
//             <div className="bg-[#F5F5F5] p-6 rounded-lg">
//                 <form onSubmit={handleSubmit}>
//                     {/* Personal Information Section */}
//                     <h3 className="text-xl font-bold mb-6 text-[#07074D]">Personal Information</h3>
//                     {[
//                         { id: 'first_name', label: 'First Name', type: 'text' },
//                         { id: 'last_name', label: 'Last Name', type: 'text' },
//                         { id: 'email', label: 'Email', type: 'email' },
//                         { id: 'phone', label: 'Phone', type: 'text' },
//                         { id: 'birth_date', label: 'Birth Date', type: 'text' },
//                         { id: 'nationality', label: 'Nationality', type: 'text' },
//                         { id: 'street', label: 'Street', type: 'text' },
//                         { id: 'city', label: 'City', type: 'text' },
//                         { id: 'state', label: 'State', type: 'text' },
//                         { id: 'country', label: 'Country', type: 'text' },
//                         { id: 'zip_code', label: 'Zip Code', type: 'text' },
//                         { id: 'referal_sourse', label: 'Referral Source', type: 'text' },
//                         { id: 'ethnicity', label: 'Ethnicity', type: 'text' },
//                         { id: 'Religion', label: 'Religion', type: 'text' },
//                         { id: 'current_employment', label: 'Current Employment', type: 'text' },
//                         { id: 'linkdein_profile', label: 'LinkedIn Profile', type: 'url' },
//                         { id: 'remote_experience', label: 'Remote Experience', type: 'text' },
//                         { id: 'refrence', label: 'Reference', type: 'text' },
//                         { id: 'expected_salary_local', label: 'Expected Salary (Local)', type: 'text' },
//                         { id: 'expected_salary_gbp', label: 'Expected Salary (GBP)', type: 'text' }
//                     ].map(field => (
//                         <div key={field.id} className="mb-5">
//                             <label htmlFor={field.id} className="mb-3 block text-base font-medium text-[#07074D]">
//                                 {field.label}
//                             </label>
//                             <input
//                                 type={field.type}
//                                 name={field.id}
//                                 id={field.id}
//                                 value={formData[field.id]}
//                                 onChange={handleInputChange}
//                                 placeholder={field.label}
//                                 className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
//                             />
//                         </div>
//                     ))}
//                     <div className="mb-5">
//                         <label htmlFor="gender" className="mb-3 block text-base font-medium text-[#07074D]">
//                             Gender
//                         </label>
//                         <select
//                             id="gender"
//                             name="gender"
//                             value={formData.gender}
//                             onChange={handleInputChange}
//                             className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
//                         >
//                             <option value="Male">Male</option>
//                             <option value="Female">Female</option>
//                             <option value="Non-Binary">Non Binary</option>
//                             <option value="Prefer not to say">Prefer not to say</option>
//                         </select>
//                     </div>
//                     <div className="mb-5">
//                         <label htmlFor="visa_requirement" className="mb-3 block text-base font-medium text-[#07074D]">
//                             Visa Requirement
//                         </label>
//                         <input
//                             type="checkbox"
//                             name="visa_requirement"
//                             id="visa_requirement"
//                             checked={formData.visa_requirement}
//                             onChange={handleInputChange}
//                             className="w-6 h-6 border border-[#e0e0e0] bg-white outline-none focus:border-[#6A64F1] focus:shadow-md"
//                         />
//                     </div>
//                     <div className="mb-5">
//                         <label htmlFor="previous_international" className="mb-3 block text-base font-medium text-[#07074D]">
//                             Previous International Experience
//                         </label>
//                         <input
//                             type="checkbox"
//                             name="previous_international"
//                             id="previous_international"
//                             checked={formData.previous_international}
//                             onChange={handleInputChange}
//                             className="w-6 h-6 border border-[#e0e0e0] bg-white outline-none focus:border-[#6A64F1] focus:shadow-md"
//                         />
//                     </div>
//                     <div className="mb-5">
//                         <label htmlFor="availabillity" className="mb-3 block text-base font-medium text-[#07074D]">
//                             Available to relocate
//                         </label>
//                         <input
//                             type="checkbox"
//                             name="availabillity"
//                             id="availabillity"
//                             checked={formData.availabillity}
//                             onChange={handleInputChange}
//                             className="w-6 h-6 border border-[#e0e0e0] bg-white outline-none focus:border-[#6A64F1] focus:shadow-md"
//                         />
//                     </div>

//                     {/* File Upload Section */}
//                     <div className="mb-5">
//                         <label htmlFor="uploaded_file" className="mb-3 block text-base font-medium text-[#07074D]">
//                             Professional Certifications (if any)
//                         </label>
//                         <input
//                             type="file"
//                             id="uploaded_file"
//                             name="uploaded_file"
//                             onChange={handleFileChange}
//                             className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
//                         />
//                     </div>

//                     {/* Education Section */}
//                     <h3 className="text-xl font-bold mb-6 text-[#07074D]">Education</h3>
//                     {formData.educations.map((education, index) => (
//                         <div key={index} className="mb-5">
//                             <div className="flex justify-between items-center mb-3">
//                                 <h4 className="text-lg font-semibold">Education {index + 1}</h4>
//                                 <button
//                                     type="button"
//                                     onClick={() => handleRemoveEducation(index)}
//                                     className="text-red-600"
//                                 >
//                                     Remove
//                                 </button>
//                             </div>
//                             {['institution', 'degree', 'fieldofstudy', 'startdate', 'enddate'].map(field => (
//                                 <div key={field} className="mb-3">
//                                     <label htmlFor={`education_${index}_${field}`} className="block text-base font-medium text-[#07074D]">
//                                         {field.charAt(0).toUpperCase() + field.slice(1)}
//                                     </label>
//                                     <input
//                                         type="text"
//                                         name={`education_${index}_${field}`}
//                                         id={`education_${index}_${field}`}
//                                         value={education[field]}
//                                         onChange={(e) => handleEducationChange(index, field, e.target.value)}
//                                         placeholder={field}
//                                         className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
//                                     />
//                                 </div>
//                             ))}
//                         </div>
//                     ))}
//                     <button
//                         type="button"
//                         onClick={handleAddEducation}
//                         className="mb-5 bg-blue-500 text-white py-2 px-4 rounded-md"
//                     >
//                         Add Education
//                     </button>

//                     {/* Work Experience Section */}
//                     <h3 className="text-xl font-bold mb-6 text-[#07074D]">Work Experience</h3>
//                     {formData.work_experiences.map((experience, index) => (
//                         <div key={index} className="mb-5">
//                             <div className="flex justify-between items-center mb-3">
//                                 <h4 className="text-lg font-semibold">Experience {index + 1}</h4>
//                                 <button
//                                     type="button"
//                                     onClick={() => handleRemoveExperience(index)}
//                                     className="text-red-600"
//                                 >
//                                     Remove
//                                 </button>
//                             </div>
//                             {['company', 'jobtitle', 'startdate', 'enddate', 'responsibilities'].map(field => (
//                                 <div key={field} className="mb-3">
//                                     <label htmlFor={`experience_${index}_${field}`} className="block text-base font-medium text-[#07074D]">
//                                         {field.charAt(0).toUpperCase() + field.slice(1)}
//                                     </label>
//                                     <input
//                                         type="text"
//                                         name={`experience_${index}_${field}`}
//                                         id={`experience_${index}_${field}`}
//                                         value={experience[field]}
//                                         onChange={(e) => handleExperienceChange(index, field, e.target.value)}
//                                         placeholder={field}
//                                         className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
//                                     />
//                                 </div>
//                             ))}
//                         </div>
//                     ))}
//                     <button
//                         type="button"
//                         onClick={handleAddExperience}
//                         className="mb-5 bg-blue-500 text-white py-2 px-4 rounded-md"
//                     >
//                         Add Work Experience
//                     </button>

//                     <button
//                         type="submit"
//                         className="bg-blue-500 text-white py-2 px-4 rounded-md"
//                     >
//                         Submit
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default JobApplicationForm;

