// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import PageTitle from '../components/pagetitle';
// import Sale01 from '../components/sale/Sale01';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

// function Login(props) {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState('');
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await fetch('http://localhost:8000/api/login/', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     email: email,
//                     password: password,
//                 }),
//             });

//             if (response.status === 200) {
//                 const data = await response.json();
//                 // Store tokens
//                 localStorage.setItem('accessToken', data.access);
//                 localStorage.setItem('refreshToken', data.refresh);
//                 console.log('Tokens stored:', data.access, data.refresh); // Debug log
//                 // Redirect to profile page
//                 navigate('/panel');
//             } else {
//                 setError('Invalid login credentials');
//             }
//         } catch (error) {
//             console.error('Login error:', error);
//             setError('An error occurred');
//         }
//     };

//     return (
//         <div>
//             <PageTitle heading='Login' title='Login' />
//             <section className="register login">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="block-text center">
//                                 <h3 className="heading">Login To Rockie</h3>
//                                 <p className="desc fs-20">Welcome back! Log In now to start trading</p>
//                             </div>
//                         </div>
//                         <div className="col-md-12">
//                             <Tabs>
//                                 <TabList>
//                                     <Tab><h6 className="fs-16">Email</h6></Tab>
//                                     <Tab><h6 className="fs-16">Mobile</h6></Tab>
//                                 </TabList>
//                                 <TabPanel>
//                                     <div className="content-inner">
//                                         <form onSubmit={handleSubmit}>
//                                             <div className="form-group">
//                                                 <label>Email/ID</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="Please fill in the email form."
//                                                     value={email}
//                                                     onChange={(e) => setEmail(e.target.value)}
//                                                 />
//                                             </div>
//                                             <div className="form-group s1">
//                                                 <label>Password</label>
//                                                 <input
//                                                     type="password"
//                                                     className="form-control"
//                                                     placeholder="Please enter a password."
//                                                     value={password}
//                                                     onChange={(e) => setPassword(e.target.value)}
//                                                 />
//                                             </div>
//                                             <button type="submit" className="btn-action">Login</button>
//                                             {error && <p className="text-danger">{error}</p>}
//                                         </form>
//                                     </div>
//                                 </TabPanel>
//                                 {/* Mobile tab can be handled similarly */}
//                             </Tabs>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             {/* <Sale01 /> */}
//         </div>
//     );
// }

// export default Login;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const SignInForm = ({ email, setEmail, password, setPassword, handleSubmit, error }) => (
// 	<form className="mt-4" onSubmit={handleSubmit}>
// 		<div className="mb-4">
// 			<input
// 				type="text"
// 				className="w-full bg-transparent min-h-[48px] leading-10 px-4 p-2 rounded-md outline-none border border-gray-400 dark:border-slate-700 focus:border-blue-600 dark:focus:border-blue-700"
// 				placeholder="Enter your username"
// 				value={email}
// 				onChange={(e) => setEmail(e.target.value)}
// 				// autoComplete="email"
// 				required
// 			/>
// 		</div>
// 		<div className="mb-4">
// 			<input
// 				type="password"
// 				className="w-full bg-transparent min-h-[48px] leading-10 px-4 p-2 rounded-md outline-none border border-gray-400 dark:border-slate-700 focus:border-blue-600 dark:focus:border-blue-700"
// 				placeholder="Enter your password"
// 				value={password}
// 				onChange={(e) => setPassword(e.target.value)}
// 				autoComplete="current-password"
// 				required
// 			/>
// 		</div>
// 		{error && <p className="text-red-600">{error}</p>}
// 		<button
// 			type="submit"
// 			className="font-semibold bg-[#00416A] text-white py-3 px-8 rounded w-full mt-6"
// 		>
// 			Log In
// 		</button>

// 		<div className="text-center mt-5 pt-4">
// 			<p className="mb-0">
// 				No Account?{" "}
// 				<a
// 					href="/register"
// 					className="text-[#00416A]"
// 				>
// 					Create An Account
// 				</a>
// 			</p>
// 		</div>
// 	</form>
// );

// function Login() {
// 	const [email, setEmail] = useState('');
// 	const [password, setPassword] = useState('');
// 	const [error, setError] = useState('');
// 	const navigate = useNavigate();

// 	const handleSubmit = async (e) => {
// 		e.preventDefault();

// 		try {
// 			const response = await fetch('http://127.0.0.1:8000/api/login/', {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json',
// 				},
// 				body: JSON.stringify({
// 					email: email,
// 					password: password,
// 				}),
// 			});

// 			if (response.status === 200) {
// 				const data = await response.json();
// 				// Storing the secret_key in localStorage
// 				localStorage.setItem('secret_key', data.secret_key);
// 				localStorage.setItem('accessToken', data.access);
// 				localStorage.setItem('refreshToken', data.refresh);
// 				// Redirecting to the admin panel page
// 				navigate('/panel');
// 			} else {
// 				setError('Invalid login credentials');
// 			}
// 		} catch (error) {
// 			setError('An error occurred');
// 		}
// 	};

// 	return (
// 		<div className="bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white overflow-hidden relative max-w-[90vw] sm:max-w-[80vw] lg:max-w-[45vw] xl:max-w-[30vw] rounded mx-auto my-14 p-10">
// 			<div className="p-0 relative">
// 				<div className="grid grid-cols-12 py-4 justify-center">
// 					<div className="col-span-12">
// 						<div className="md:p-5">
// 							<h2 className="font-bold text-3xl  text-[#00416A] leading-6 text-center p-2 mt-4 mb-2">
// 								Log In
// 							</h2>
// 							<SignInForm
// 								email={email}
// 								setEmail={setEmail}
// 								password={password}
// 								setPassword={setPassword}
// 								handleSubmit={handleSubmit}
// 								error={error}
// 							/>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

// export default Login;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignInForm = ({ email, setEmail, password, setPassword, handleSubmit, error }) => (
	<form className="mt-4" onSubmit={handleSubmit}>
		<div className="mb-4">
			<input
				type="email"
				className="w-full bg-transparent min-h-[48px] leading-10 px-4 p-2 rounded-md outline-none border border-gray-400 dark:border-slate-700 focus:border-blue-600 dark:focus:border-blue-700"
				placeholder="Enter your email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				// autoComplete="email"
				required
			/>
		</div>
		<div className="mb-4">
			<input
				type="password"
				className="w-full bg-transparent min-h-[48px] leading-10 px-4 p-2 rounded-md outline-none border border-gray-400 dark:border-slate-700 focus:border-blue-600 dark:focus:border-blue-700"
				placeholder="Enter your password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				autoComplete="current-password"
				required
			/>
		</div>
		{error && <p className="text-red-600">{error}</p>}
		<button
			type="submit"
			className="font-semibold bg-[#00416A] text-white py-3 px-8 rounded w-full mt-6"
		>
			Log In
		</button>

		<div className="text-center mt-5 pt-4">
			<p className="mb-0">
				No Account?{" "}
				<a
					href="/register"
					className="text-[#00416A]"
				>
					Create An Account
				</a>
			</p>
		</div>
		<div className="text-center mt-5 pt-4">
			<p className="mb-0">
				<a
					href="/reset-password"
					className="text-[#00416A]"
				>
					Forgot Password?
				</a>
			</p>
		</div>
	</form>
);

function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch('https://profit-backend.onrender.com/api/login/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
					password: password,
				}),
			});

			if (response.status === 200) {
				const data = await response.json();

				// Logging the response data
				console.log('Login response:', data);

				// Storing the secret_key in localStorage
				localStorage.setItem('secret_key', data.secret_key);
				localStorage.setItem('accessToken', data.access);
				localStorage.setItem('refreshToken', data.refresh);

				// Logging stored values to ensure they are saved correctly
				console.log('Stored secret_key:', localStorage.getItem('secret_key'));
				console.log('Stored accessToken:', localStorage.getItem('accessToken'));
				console.log('Stored refreshToken:', localStorage.getItem('refreshToken'));

				// Redirecting to the admin panel page
				navigate('/panel');
			} else {
				setError('Invalid login credentials');
				console.log('Login failed with status:', response.status);
			}
		} catch (error) {
			setError('An error occurred');
			console.error('Error during login request:', error);
		}
	};

	return (
		<div className="bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white overflow-hidden relative max-w-[90vw] sm:max-w-[80vw] lg:max-w-[45vw] xl:max-w-[30vw] rounded mx-auto my-14 p-10">
			<div className="p-0 relative">
				<div className="grid grid-cols-12 py-4 justify-center">
					<div className="col-span-12">
						<div className="md:p-5">
							<h2 className="font-bold text-3xl  text-[#00416A] leading-6 text-center p-2 mt-4 mb-2">
								Log In
							</h2>
							<SignInForm
								email={email}
								setEmail={setEmail}
								password={password}
								setPassword={setPassword}
								handleSubmit={handleSubmit}
								error={error}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
