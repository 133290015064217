import React , {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './styles.css';

function Coinlist01() {
  const [dataCoin, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://profit-backend.onrender.com/banks/')
      .then(response => {
        const sortedData = response.data.sort((a, b) => parseFloat(b.profitrate) - parseFloat(a.profitrate));
        setData(sortedData);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className="coin-list">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text">
              <h3 className="heading">Bank Profit-rates</h3>
              {/* <Link to="#" className="btn-action-2">See All Banks</Link> */}
            </div>

            <div className="coin-list__main">
              <Tabs>
                <TabList>
                  <Tab>All Banks</Tab>
                </TabList>

                <TabPanel>
                  <div className="content-inner">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Name</th>
                          <th scope="col">Saving</th>
                          <th scope="col">TDR-1 month</th>
                          <th scope="col">TDR-3 month</th>
                          <th scope="col">TDR-annual</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataCoin.map(coin => (
                          <tr key={coin.id}>
                            <td><span className="icon-star"></span></td>
                            <td>
                              <Link to="#">
                                <img src={coin.image} alt="bank" className="bank-image"/>
                                <span className="bank-name">{coin.name}</span>
                                <span className="unit">{coin.type}</span>
                              </Link>
                            </td>
                            <td className="bold">{coin.profitrate}</td>
                            <td className={coin.class}>{coin.profitrate_1month}</td>
                            <td className="bold">{coin.profitrate_3month}</td>
                            <td className="bold">{coin.profitrate_annual}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Coinlist01;