import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEnvelope, faLock, faUser, faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import styles for react-phone-input-2
import { TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    first_name: '',
    last_name: '',
    birth_day: '',
    birth_month: '',
    birth_year: '',
    phone: '',
    nationality: '',
  });
  const [otp, setOtp] = useState('');  // OTP as a string for 6 digits
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [emailForOtp, setEmailForOtp] = useState('');

  const otpRefs = useRef([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      phone: value,
      nationality: country.name
    });
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasUpperCase = /[A-Z]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    return (
      password.length >= minLength &&
      hasNumber.test(password) &&
      hasUpperCase.test(password) &&
      hasSpecialChar.test(password)
    );
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) newErrors.username = 'Username is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.password) newErrors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    if (!validatePassword(formData.password))
      newErrors.password = 'Password must be at least 8 characters long, contain at least one number, one uppercase letter, and one special character.';
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.birth_day || !formData.birth_month || !formData.birth_year)
      newErrors.birth_date = 'Complete birth date is required';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.nationality) newErrors.nationality = 'Country is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const birth_date = `${formData.birth_year}-${formData.birth_month.padStart(2, '0')}-${formData.birth_day.padStart(2, '0')}`;

    const {
      confirmPassword,
      birth_day,
      birth_month,
      birth_year,
      ...registrationData
    } = formData;

    registrationData.birth_date = birth_date;

    try {
      const response = await axios.post(
        'https://profit-backend.onrender.com/api/register/',
        registrationData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setMessage('User registered successfully');
      // Store the username in localStorage
      localStorage.setItem('username', formData.username);
      setErrors({});
      setFormData({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        birth_day: '',
        birth_month: '',
        birth_year: '',
        phone: '',
        nationality: '',
      });
      setEmailForOtp(formData.email);
      setShowOtpModal(true);
    } catch (error) {
      console.error('Error Response Data:', error.response?.data || error.message);
      setErrors(
        error.response?.data || { non_field_errors: 'Something went wrong. Please try again.' }
      );
      setMessage('User registration failed');
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const updatedOtp = otp.split('');
    updatedOtp[index] = value;
    setOtp(updatedOtp.join(''));

    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const response = await axios.post('https://profit-backend.onrender.com/api/verify/', {
        email: emailForOtp,
        otp: otp 
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        alert("OTP verified successfully!");
        setShowOtpModal(false);
        navigate("/education");
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      alert(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  return (
    <section className="ezy__signup15 light flex items-center justify-center py-14 md:py-24 bg-white dark:bg-[#0b1727] text-black text-opacity-90 dark:text-white overflow-hidden relative">
      <br />
      <br />
      <div className="container px-4 mx-auto">
        <div className="flex justify-center">
          <div className="w-full md:w-2/3">
            <div className="bg-white dark:bg-slate-800 shadow-xl p-6 lg:p-12">
              <div className="flex flex-wrap justify-between items-center">
                <div className="w-full lg:w-1/2 lg:order-2">
                  <div className="flex flex-col items-center justify-center h-full mt-12 lg:mt-0">
                    <img
                      src="https://cdn.easyfrontend.com/pictures/sign-in-up/abstract1.png"
                      alt=""
                    />
                    <div className="text-center mt-12">
                      <a
                        href="/login"
                        className="underline hover:text-blue-600 duration-300"
                      >
                        I am already a member
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 mt-6 lg:mt-0">
                  <div className="flex flex-col h-full p-2 lg:p-6 xl:p-12">
                    <h2 className="text-3xl md:text-[45px] font-bold mb-2">
                      Sign Up
                    </h2>
                    <form onSubmit={handleSubmit} noValidate>
                      {/* Username Field */}
                      <div className="w-full relative mb-6">
                        <input
                          type="text"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="username"
                          placeholder="Username"
                          value={formData.username}
                          onChange={handleChange}
                        />
                        <FontAwesomeIcon
                          icon={faUser}
                          className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
                        />
                        {errors.username && (
                          <p className="text-red-600">{errors.username}</p>
                        )}
                      </div>

                      {/* Email Field */}
                      <div className="w-full relative mb-6">
                        <input
                          type="email"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
                        />
                        {errors.email && (
                          <p className="text-red-600">{errors.email}</p>
                        )}
                      </div>

                      {/* Password Field */}
                      <div className="w-full relative mb-6">
                        <input
                          type="password"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <FontAwesomeIcon
                          icon={faLock}
                          className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
                        />
                        {errors.password && (
                          <p className="text-red-600">{errors.password}</p>
                        )}
                      </div>

                      {/* Confirm Password Field */}
                      <div className="w-full relative mb-6">
                        <input
                          type="password"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                        />
                        <FontAwesomeIcon
                          icon={faLock}
                          className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
                        />
                        {errors.confirmPassword && (
                          <p className="text-red-600">{errors.confirmPassword}</p>
                        )}
                      </div>

                      {/* First Name Field */}
                      <div className="w-full relative mb-6">
                        <input
                          type="text"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="first_name"
                          placeholder="First Name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && (
                          <p className="text-red-600">{errors.first_name}</p>
                        )}
                      </div>

                      {/* Last Name Field */}
                      <div className="w-full relative mb-6">
                        <input
                          type="text"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="last_name"
                          placeholder="Last Name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && (
                          <p className="text-red-600">{errors.last_name}</p>
                        )}
                      </div>

                      {/* Date of Birth Fields */}
                      <div className="mb-6">
                        <h3 className="text-sl font-semibold mb-2">Date of Birth</h3>
                        <div className="flex mb-6 space-x-2">
                          <div className="w-1/3">
                            <select
                              className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                              name="birth_day"
                              value={formData.birth_day}
                              onChange={handleChange}
                            >
                              <option value="">Day</option>
                              {Array.from({ length: 31 }, (_, i) => (
                                <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-1/3">
                            <select
                              className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                              name="birth_month"
                              value={formData.birth_month}
                              onChange={handleChange}
                            >
                              <option value="">Month</option>
                              {[
                                'January', 'February', 'March', 'April', 'May', 'June',
                                'July', 'August', 'September', 'October', 'November', 'December'
                              ].map((month, index) => (
                                <option key={index + 1} value={String(index + 1).padStart(2, '0')}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-1/3">
                            <select
                              className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                              name="birth_year"
                              value={formData.birth_year}
                              onChange={handleChange}
                            >
                              <option value="">Year</option>
                              {Array.from({ length: 100 }, (_, i) => (
                                <option key={i + 1920} value={i + 1920}>
                                  {i + 1920}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {errors.birth_date && (
                          <p className="text-red-600">{errors.birth_date}</p>
                        )}
                      </div>

                      {/* Phone Field */}
                      <div className="w-full relative mb-6">
                        <PhoneInput
                          country={'us'}
                          value={formData.phone}
                          onChange={handlePhoneChange}
                          containerClass="phone-input-container"
                          inputClass="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                        />
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
                        />
                        {errors.phone && (
                          <p className="text-red-600">{errors.phone}</p>
                        )}
                      </div>

                      <div className="w-full relative mb-6">
                        <input
                          type="text"
                          className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                          name="nationality"
                          placeholder="Country"
                          value={formData.nationality}
                          onChange={handleChange}
                        />
                        <FontAwesomeIcon
                          icon={faGlobe}
                          className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
                        />
                        {errors.nationality && <p>{errors.nationality}</p>}
                      </div>

                      {/* Submit Button */}
                      <div className="w-full">
                        <button
                          type="submit"
                          className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full w-full"
                        >
                          Sign Up <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* OTP Modal */}
        <TEModal show={showOtpModal} setShow={setShowOtpModal} staticBackdrop={true} tabIndex="-1">
          <TEModalDialog className="fixed inset-0 flex items-center justify-center z-50 mt-20">
            <TEModalContent className="relative z-50 max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <TEModalHeader>
                <h5 className="text-xl font-medium leading-normal text-gray-800 dark:text-gray-200">
                  Enter OTP
                </h5>
                <button
                  type="button"
                  onClick={() => setShowOtpModal(false)}
                  className="text-red-600"
                >
                  &times;
                </button>
              </TEModalHeader>
              <TEModalBody>
                <p className="mb-4 text-center text-gray-700 dark:text-gray-300">
                  An OTP has been sent to your email: <strong>{emailForOtp}</strong>
                </p>
                <div className="flex justify-center mb-4">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      className="otp-input border rounded text-center text-lg m-1 p-2 w-12"
                      value={otp[index] || ''}
                      onChange={(e) => handleOtpChange(e, index)}
                      ref={(el) => (otpRefs.current[index] = el)}
                    />
                  ))}
                </div>
              </TEModalBody>
              <TEModalFooter className="flex justify-center">
                <button
                  onClick={handleOtpSubmit}
                  className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
                >
                  Verify OTP
                </button>
              </TEModalFooter>
            </TEModalContent>
          </TEModalDialog>
        </TEModal>
      </div>
    </section>
  );
};

export default Register;

// import React, { useState, useRef } from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight, faEnvelope, faLock, faUser, faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons";
// import axios from 'axios';
// import EducationForm from '../components/educationform/EducationForm';
// import { faCalendar } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css'; // Import styles for react-phone-input-2
// import { TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";


// const Register = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//     confirmPassword: '',
//     first_name: '',
//     last_name: '',
//     birth_day: '',
//     birth_month: '',
//     birth_year: '',
//     phone: '',
//     nationality: '',
//   });
//   const [otp, setOtp] = useState('');  // OTP as a string for 6 digits
//   const [showOtpModal, setShowOtpModal] = useState(false);
//   const [showEducationModal, setShowEducationModal] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [message, setMessage] = useState('');
//   const [emailForOtp, setEmailForOtp] = useState('');
//   const [educationData, setEducationData] = useState({
//     institution: '',
//     degree: '',
//     fieldofstudy: '',
//     startdate: '',
//     enddate: '',
//     grade: ''
//   });

//   const otpRefs = useRef([]);

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   };

// // Modal Dialog Styles
// // const dialogStyles = "bg-white dark:bg-gray-800 max-w-lg mx-auto my-8 p-4 rounded-lg shadow-lg";
//   const handlePhoneChange = (value, country) => {
//     setFormData({
//       ...formData,
//       phone: value,
//       nationality: country.name
//     });
//   };

//   const validatePassword = (password) => {
//     const minLength = 8;
//     const hasNumber = /\d/;
//     const hasUpperCase = /[A-Z]/;
//     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

//     return (
//       password.length >= minLength &&
//       hasNumber.test(password) &&
//       hasUpperCase.test(password) &&
//       hasSpecialChar.test(password)
//     );
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.username) newErrors.username = 'Username is required';
//     if (!formData.email) newErrors.email = 'Email is required';
//     if (!formData.password) newErrors.password = 'Password is required';
//     if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
//     if (!validatePassword(formData.password))
//       newErrors.password = 'Password must be at least 8 characters long, contain at least one number, one uppercase letter, and one special character.';
//     if (!formData.first_name) newErrors.first_name = 'First name is required';
//     if (!formData.last_name) newErrors.last_name = 'Last name is required';
//     if (!formData.birth_day || !formData.birth_month || !formData.birth_year)
//       newErrors.birth_date = 'Complete birth date is required';
//     if (!formData.phone) newErrors.phone = 'Phone number is required';
//     if (!formData.nationality) newErrors.nationality = 'Country is required';
//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length > 0) {
//       setErrors(formErrors);
//       return;
//     }

//     const birth_date = `${formData.birth_year}-${formData.birth_month.padStart(2, '0')}-${formData.birth_day.padStart(2, '0')}`;

//     const {
//       confirmPassword,
//       birth_day,
//       birth_month,
//       birth_year,
//       ...registrationData
//     } = formData;

//     registrationData.birth_date = birth_date;

//     try {
//       const response = await axios.post(
//         'https://profit-backend.onrender.com/api/register/',
//         registrationData,
//         {
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         }
//       );
//       setMessage('User registered successfully');
//       setErrors({});
//       setFormData({
//         username: '',
//         email: '',
//         password: '',
//         confirmPassword: '',
//         first_name: '',
//         last_name: '',
//         birth_day: '',
//         birth_month: '',
//         birth_year: '',
//         phone: '',
//         nationality: '',
//       });
//       setEmailForOtp(formData.email);
//       setUsernameForEducation(formData.username);
//       setShowOtpModal(true);
//     } catch (error) {
//       console.error('Error Response Data:', error.response?.data || error.message);
//       setErrors(
//         error.response?.data || { non_field_errors: 'Something went wrong. Please try again.' }
//       );
//       setMessage('User registration failed');
//     }
//   };
  
//   // Handle input changes
//   const handleEducationChange = (e) => {
//     const { name, value } = e.target;
//     setEducationData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleOtpChange = (e, index) => {
//     const value = e.target.value;
//     const updatedOtp = otp.split('');
//     updatedOtp[index] = value;
//     setOtp(updatedOtp.join(''));

//     if (value && index < 5) {
//       otpRefs.current[index + 1].focus();
//     }
//   };

//   const handleOtpSubmit = async () => {
//     try {
//       const response = await axios.post('http://127.0.0.1:8000/api/verify-otp/', {
//         email: emailForOtp,
//         otp: otp 
//       }, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.status === 200) {
//         alert("OTP verified successfully!");
//         setShowOtpModal(false);
//         setShowEducationModal(true); // Open education modal after OTP verification
//       } else {
//         alert("Invalid OTP. Please try again.");
//       }
//     } catch (error) {
//       alert(`Error: ${error.response?.data?.error || error.message}`);
//     }
//   };
  

//   const [usernameForEducation, setUsernameForEducation] = useState('');
//   // const [showEducationForm, setShowEducationForm] = useState('');

//   const handleEducationSubmit = async (educationData) => {
//     try {
//       // Include the username in the educationData
//       const dataToSubmit = {
//         ...educationData,
//         username: usernameForEducation,
//       };

//       await axios.post(
//         'http://127.0.0.1:8000/api/add-education/',
//         dataToSubmit,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       alert('Education information submitted successfully!');
//       setShowEducationModal(false); // Close the modal after successful submission
//     } catch (error) {
//       alert(`Error: ${error.response?.data?.error || error.message}`);
//     }
//   };

//   return (
//     <section className="ezy__signup15 light flex items-center justify-center py-14 md:py-24 bg-white dark:bg-[#0b1727] text-black text-opacity-90 dark:text-white overflow-hidden relative">
//       <div className="container px-4 mx-auto">
//         <div className="flex justify-center">
//           <div className="w-full md:w-2/3">
//             <div className="bg-white dark:bg-slate-800 shadow-xl p-6 lg:p-5">
//               <div className="flex flex-wrap justify-between items-center">
//                 <div className="w-full lg:w-1/2 lg:order-2">
//                   <div className="flex flex-col items-center justify-center h-full mt-12 lg:mt-0">
//                     <img
//                       src="https://cdn.easyfrontend.com/pictures/sign-in-up/abstract1.png"
//                       alt=""
//                     />
//                     <div className="text-center mt-12">
//                       <a
//                         href="/login"
//                         className="underline hover:text-blue-600 duration-300"
//                       >
//                         I am already a member
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="w-full lg:w-1/2 mt-6 lg:mt-0">
//                   <div className="flex flex-col h-full p-2 lg:p-6 xl:p-12">
//                     <h2 className="text-3xl md:text-[45px] font-bold mb-2">
//                       Sign Up
//                     </h2>
//                     <form onSubmit={handleSubmit} noValidate>
//                       {/* Username Field */}
//                       <div className="w-full relative mb-6">
//                         <input
//                           type="text"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="username"
//                           placeholder="Username"
//                           value={formData.username}
//                           onChange={handleChange}
//                         />
//                         <FontAwesomeIcon
//                           icon={faUser}
//                           className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
//                         />
//                         {errors.username && (
//                           <p className="text-red-600">{errors.username}</p>
//                         )}
//                       </div>

//                       {/* Email Field */}
//                       <div className="w-full relative mb-6">
//                         <input
//                           type="email"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="email"
//                           placeholder="Email"
//                           value={formData.email}
//                           onChange={handleChange}
//                         />
//                         <FontAwesomeIcon
//                           icon={faEnvelope}
//                           className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
//                         />
//                         {errors.email && (
//                           <p className="text-red-600">{errors.email}</p>
//                         )}
//                       </div>

//                       {/* Password Field */}
//                       <div className="w-full relative mb-6">
//                         <input
//                           type="password"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="password"
//                           placeholder="Password"
//                           value={formData.password}
//                           onChange={handleChange}
//                         />
//                         <FontAwesomeIcon
//                           icon={faLock}
//                           className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
//                         />
//                         {errors.password && (
//                           <p className="text-red-600">{errors.password}</p>
//                         )}
//                       </div>

//                       {/* Confirm Password Field */}
//                       <div className="w-full relative mb-6">
//                         <input
//                           type="password"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="confirmPassword"
//                           placeholder="Confirm Password"
//                           value={formData.confirmPassword}
//                           onChange={handleChange}
//                         />
//                         <FontAwesomeIcon
//                           icon={faLock}
//                           className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
//                         />
//                         {errors.confirmPassword && (
//                           <p className="text-red-600">{errors.confirmPassword}</p>
//                         )}
//                       </div>

//                       {/* First Name Field */}
//                       <div className="w-full relative mb-6">
//                         <input
//                           type="text"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="first_name"
//                           placeholder="First Name"
//                           value={formData.first_name}
//                           onChange={handleChange}
//                         />
//                         {errors.first_name && (
//                           <p className="text-red-600">{errors.first_name}</p>
//                         )}
//                       </div>

//                       {/* Last Name Field */}
//                       <div className="w-full relative mb-6">
//                         <input
//                           type="text"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="last_name"
//                           placeholder="Last Name"
//                           value={formData.last_name}
//                           onChange={handleChange}
//                         />
//                         {errors.last_name && (
//                           <p className="text-red-600">{errors.last_name}</p>
//                         )}
//                       </div>

//                       {/* Date of Birth Fields */}
//                       <div className="mb-6">
//                         <h3 className="text-sl font-semibold mb-2">Date of Birth</h3>
//                         <div className="flex mb-6 space-x-2">
//                           <div className="w-1/3">
//                             <select
//                               className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                               name="birth_day"
//                               value={formData.birth_day}
//                               onChange={handleChange}
//                             >
//                               <option value="">Day</option>
//                               {Array.from({ length: 31 }, (_, i) => (
//                                 <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
//                                   {i + 1}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                           <div className="w-1/3">
//                             <select
//                               className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                               name="birth_month"
//                               value={formData.birth_month}
//                               onChange={handleChange}
//                             >
//                               <option value="">Month</option>
//                               {[ 'January', 'February', 'March', 'April', 'May', 'June',
//                                 'July', 'August', 'September', 'October', 'November', 'December' ].map((month, index) => (
//                                 <option key={index + 1} value={String(index + 1).padStart(2, '0')}>
//                                   {month}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                           <div className="w-1/3">
//                             <select
//                               className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                               name="birth_year"
//                               value={formData.birth_year}
//                               onChange={handleChange}
//                             >
//                               <option value="">Year</option>
//                               {Array.from({ length: 100 }, (_, i) => (
//                                 <option key={i + 1920} value={i + 1920}>
//                                   {i + 1920}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </div>
//                         {errors.birth_date && (
//                           <p className="text-red-600">{errors.birth_date}</p>
//                         )}
//                       </div>

//                       {/* Phone Field */}
//                       <div className="w-full relative mb-6">
//                         <PhoneInput
//                           country={'us'}
//                           value={formData.phone}
//                           onChange={handlePhoneChange}
//                           containerClass="phone-input-container"
//                           inputClass="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                         />
//                         <FontAwesomeIcon
//                           icon={faPhone}
//                           className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
//                         />
//                         {errors.phone && (
//                           <p className="text-red-600">{errors.phone}</p>
//                         )}
//                       </div>

//                       <div className="w-full relative mb-6">
//                         <input
//                           type="text"
//                           className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                           name="nationality"
//                           placeholder="Country"
//                           value={formData.nationality}
//                           onChange={handleChange}
//                         />
//                         <FontAwesomeIcon
//                           icon={faGlobe}
//                           className="absolute top-1/2 -translate-y-1/2 right-2 opacity-80"
//                         />
//                         {errors.nationality && <p>{errors.nationality}</p>}
//                       </div>

//                       {/* Submit Button */}
//                       <div className="w-full">
//                         <button
//                           type="submit"
//                           className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full w-full"
//                         >
//                           Sign Up <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* OTP Modal */}
//         <TEModal show={showOtpModal} setShow={setShowOtpModal} staticBackdrop={true} tabIndex="-1">
//           <TEModalDialog className="fixed inset-0 flex items-center justify-center z-50 mt-20">
//             <TEModalContent className="relative z-50 max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
//               <TEModalHeader>
//                 <h5 className="text-xl font-medium leading-normal text-gray-800 dark:text-gray-200">
//                   Enter OTP
//                 </h5>
//                 <button
//                   type="button"
//                   onClick={() => setShowOtpModal(false)}
//                   className="text-red-600"
//                 >
//                   &times;
//                 </button>
//               </TEModalHeader>
//               <TEModalBody>
//                 <p className="mb-4 text-center text-gray-700 dark:text-gray-300">
//                   An OTP has been sent to your email: <strong>{emailForOtp}</strong>
//                 </p>
//                 <div className="flex justify-center mb-4">
//                   {[...Array(6)].map((_, index) => (
//                     <input
//                       key={index}
//                       type="text"
//                       maxLength="1"
//                       className="otp-input border rounded text-center text-lg m-1 p-2 w-12"
//                       value={otp[index] || ''}
//                       onChange={(e) => handleOtpChange(e, index)}
//                       ref={(el) => (otpRefs.current[index] = el)}
//                     />
//                   ))}
//                 </div>
//               </TEModalBody>
//               <TEModalFooter className="flex justify-center">
//                 <button
//                   onClick={handleOtpSubmit}
//                   className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
//                 >
//                   Verify OTP
//                 </button>
//               </TEModalFooter>
//             </TEModalContent>
//           </TEModalDialog>
//         </TEModal>
        
//         {/* Education Modal */}
//         {/* {showEducationModal && (
//         <EducationForm
//           username={usernameForEducation}
//           onSubmit={handleEducationFormSubmit}
//           onClose={() => setShowEducationModal(false)}
//         />
//       )} */}
//         <div className="inset-0 z-50 overflow-y-auto">
//               {/* <div className={dialogStyles}> */}
//               {/* <TEModal show={showEducationModal} setShow={setShowEducationModal} staticBackdrop={true} tabIndex="-1">
//         <TEModalDialog className="fixed inset-0 flex items-center justify-center z-50 mt-20">
//           <TEModalContent className="relative z-50 max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
//             <TEModalHeader>
//               <h5 className="text-xl font-medium leading-normal text-gray-800 dark:text-gray-200">
//                 Education Information
//               </h5>
//               <button
//                 type="button"
//                 onClick={() => setShowEducationModal(false)}
//                 className="text-red-600"
//               >
//                 &times;
//               </button>
//             </TEModalHeader>
//             <TEModalBody>
//               <form onSubmit={handleEducationSubmit}>
//                 <div className="mb-6">
//                   <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Degree</label>
//                   <input
//                     type="text"
//                     name="degree"
//                     value={educationData.degree}
//                     onChange={handleEducationChange}
//                     className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                     placeholder="e.g., B.Sc., M.A., etc."
//                   />
//                 </div>
//                 <div className="mb-6">
//                   <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Field of Study</label>
//                   <input
//                     type="text"
//                     name="fieldofstudy"
//                     value={educationData.fieldofstudy}
//                     onChange={handleEducationChange}
//                     className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                     placeholder="e.g., Computer Science, Literature, etc."
//                   />
//                 </div>
//                 <div className="mb-6">
//                   <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">University</label>
//                   <input
//                     type="text"
//                     name="institution"
//                     value={educationData.institution}
//                     onChange={handleEducationChange}
//                     className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                     placeholder="e.g., Harvard University"
//                   />
//                 </div>
//                 <div className="mb-6">
//                   <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Start Year</label>
//                   <input
//                     type="text"
//                     name="startyear"
//                     value={educationData.startyear}
//                     onChange={handleEducationChange}
//                     className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                     placeholder="e.g., 2022"
//                   />
//                 </div>
//                 <div className="mb-6">
//                   <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">End Year</label>
//                   <input
//                     type="text"
//                     name="endyear"
//                     value={educationData.endyear}
//                     onChange={handleEducationChange}
//                     className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                     placeholder="e.g., 2022"
//                   />
//                 </div>
//                 <div className="mb-6">
//                   <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Grade</label>
//                   <input
//                     type="text"
//                     name="grade"
//                     value={educationData.grade}
//                     onChange={handleEducationChange}
//                     className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                     placeholder="e.g., A+"
//                   />
//                 </div>
//               </form>
//             </TEModalBody>
//             <TEModalFooter className="flex justify-center">
//               <button
//                 onClick={handleEducationSubmit}
//                 className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
//               >
//                 Submit
//               </button>
//             </TEModalFooter>
//           </TEModalContent>
//         </TEModalDialog>
//       </TEModal> */}
//         </div>
//                 </div>
//       {/* </div> */}
//     </section>
//   );
// };

// export default Register;
