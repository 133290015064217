// import React, { useState, useEffect } from 'react';

// const EssaySubmissionPage = () => {
//     const [title, setTitle] = useState('');
//     const [content, setContent] = useState('');
//     const [error, setError] = useState('');
//     const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds
//     const [isTimeUp, setIsTimeUp] = useState(false);

//     useEffect(() => {
//         if (timeLeft > 0) {
//             const timerId = setInterval(() => {
//                 setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
//             }, 1000);
//             return () => clearInterval(timerId);
//         } else {
//             setIsTimeUp(true);
//             autoSubmitEssay();
//         }
//     }, [timeLeft]);

//     const formatTime = (seconds) => {
//         const minutes = Math.floor(seconds / 60);
//         const secs = seconds % 60;
//         return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (isTimeUp) {
//             setError("Time is up! You cannot submit the essay.");
//             return;
//         }

//         if (content.split(" ").length > 1000) {
//             setError("Essay must be 1000 words or less.");
//             return;
//         }

//         try {
//             const secretKey = localStorage.getItem('secret_key');
//             const response = await fetch('http://localhost:8000/api/submit-essay/', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ title, content, secret_key: secretKey }),
//             });

//             if (response.ok) {
//                 alert('Essay submitted successfully!');
//                 setTitle('');
//                 setContent('');
//                 setError('');
//             } else {
//                 setError('Failed to submit essay.');
//             }
//         } catch (err) {
//             setError('Error submitting the essay.');
//         }
//     };

//     const autoSubmitEssay = async () => {
//         if (content) {
//             try {
//                 const secretKey = localStorage.getItem('secret_key');
//                 const response = await fetch('http://localhost:8000/api/submit-essay/', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ title, content, secret_key: secretKey }),
//                 });

//                 if (response.ok) {
//                     alert('Time is up! Your essay has been automatically submitted.');
//                 } else {
//                     setError('Failed to auto-submit the essay.');
//                 }
//             } catch (err) {
//                 setError('Error auto-submitting the essay.');
//             }
//         }
//     };

//     const handlePaste = (e) => {
//         e.preventDefault();
//         setError('Pasting content is not allowed.');
//     };

//     return (
//         <div className="max-w-3xl mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
//             <h1 className="text-3xl font-bold text-center mb-6">Essay Writing Competition</h1>
//             <div className="text-center mb-4 text-red-500">
//                 <p>Time left: {formatTime(timeLeft)}</p>
//             </div>
//             <form onSubmit={handleSubmit}>
//                 <div className="mb-4">
//                     <label className="block text-gray-700 font-bold mb-2">Title</label>
//                     <input
//                         type="text"
//                         value={title}
//                         onChange={(e) => setTitle(e.target.value)}
//                         required
//                         disabled={isTimeUp}
//                         className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//                     />
//                 </div>
//                 <div className="mb-6">
//                     <label className="block text-gray-700 font-bold mb-2">Content</label>
//                     <textarea
//                         value={content}
//                         onChange={(e) => setContent(e.target.value)}
//                         onPaste={handlePaste}
//                         rows="10"
//                         required
//                         disabled={isTimeUp}
//                         className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//                     />
//                 </div>
//                 {error && <p className="text-red-500 font-bold text-center mb-4">{error}</p>}
//                 <button
//                     type="submit"
//                     disabled={isTimeUp}
//                     className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 disabled:bg-gray-400"
//                 >
//                     Submit Essay
//                 </button>
//             </form>
//         </div>
//     );
// };

// export default EssaySubmissionPage;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const EssaySubmissionPage = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState('');
    const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds
    const [isTimeUp, setIsTimeUp] = useState(false);
    const [hasStarted, setHasStarted] = useState(false); // State to manage if the timer has started
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        if (hasStarted && timeLeft > 0) { // Start timer only if hasStarted is true
            const timerId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
            }, 1000);
            return () => clearInterval(timerId);
        } else if (timeLeft === 0) {
            setIsTimeUp(true);
            autoSubmitEssay();
        }
    }, [timeLeft, hasStarted]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isTimeUp) {
            setError("Time is up! You cannot submit the essay.");
            return;
        }

        if (content.split(" ").length > 1000) {
            setError("Essay must be 1000 words or less.");
            return;
        }

        try {
            const secretKey = localStorage.getItem('secret_key');
            const response = await fetch('https://profit-backend.onrender.com/api/submit-essay/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title, content, secret_key: secretKey }),
            });

            if (response.ok) {
                alert('Essay submitted successfully!');
                setTitle('');
                setContent('');
                setError('');
                navigate('/panel'); // Redirect to the panel page
            } else {
                setError('Failed to submit essay.');
            }
        } catch (err) {
            setError('Error submitting the essay.');
        }
    };

    const autoSubmitEssay = async () => {
        if (content) {
            try {
                const secretKey = localStorage.getItem('secret_key');
                const response = await fetch('https://profit-backend.onrender.com/api/submit-essay/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ title, content, secret_key: secretKey }),
                });

                if (response.ok) {
                    alert('Time is up! Your essay has been automatically submitted.');
                    navigate('/panel'); // Redirect to the panel page after auto-submit
                } else {
                    setError('Failed to auto-submit the essay.');
                }
            } catch (err) {
                setError('Error auto-submitting the essay.');
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        setError('Pasting content is not allowed.');
    };

    const handleStart = () => {
        setHasStarted(true); // Start the timer
    };

    return (
        <div className="max-w-3xl mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
            <br/>
            <h1 className="text-3xl font-bold text-center mb-6">Essay Writing Competition</h1>
            <div className="text-center mb-4 text-red-500">
                <p>Time left: {formatTime(timeLeft)}</p>
            </div>
            {!hasStarted && ( // Show Start button if the timer hasn't started
                <button
                    onClick={handleStart}
                    className="w-full bg-green-500 text-white font-bold py-2 px-4 mb-6 rounded-lg hover:bg-green-700 transition duration-300"
                >
                    Start Writing
                </button>
            )}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">Title</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                        disabled={!hasStarted || isTimeUp} // Disable input if not started or time is up
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 font-bold mb-2">Content</label>
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        onPaste={handlePaste}
                        rows="10"
                        required
                        disabled={!hasStarted || isTimeUp} // Disable input if not started or time is up
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                {error && <p className="text-red-500 font-bold text-center mb-4">{error}</p>}
                <button
                    type="submit"
                    disabled={!hasStarted || isTimeUp} // Disable submit button if not started or time is up
                    className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 disabled:bg-gray-400"
                >
                    Submit Essay
                </button>
            </form>
        </div>
    );
};

export default EssaySubmissionPage;

