import React from 'react';
import './Popup.scss';

const Popup = ({ show, onClose, question, options, correctAnswer }) => {
  if (!show) {
    return null;
  }

  const handleOptionClick = (option) => {
    if (option === correctAnswer) {
      window.location.href = '/register'; // Replace with your desired page URL
    } else {
      alert('Incorrect! Please try again.');
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>{question}</h2>
        <div className="popup-options">
          {options.map((option, index) => (
            <button key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </button>
          ))}
        </div>
        <button className="popup-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
