import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCamera,
	faCannabis,
	faRandom,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const serviceList = [
	{
		color: "#00416A",
		icon: faCannabis,
		title: "Profit Rate Comparisons",
		description:
			"Access Detailed Comparisons Of Profit Rates From Leading Banks.",
	},
	{
		color: "#00416A",
		icon: faRandom,
		title: "Financial Tools",
		description:
			"Utilize Our Calculators And Tools To Plan Your Savings And Investments Effectively.",
	},
	{
		color: "#00416A",
		icon: faCamera,
		title: "Educational Resources",
		description:
			"Learn More About Financial Management Through Our Blog And Guides.",
	},
];

const ServiceItem = ({ service }) => (
	<div className="text-center xl:p-6">
		<div
			className={`w-[72px] h-[72px] rounded-full text-[32px] inline-flex justify-center items-center bg-${service.color}-400 bg-opacity-10 text-${service.color}-400 mb-6`}
			style={{ backgroundColor: `rgba(${parseInt(service.color.slice(1, 3), 16)}, ${parseInt(service.color.slice(3, 5), 16)}, ${parseInt(service.color.slice(5, 7), 16)}, 0.1)`, color: service.color }}
		>
			<FontAwesomeIcon icon={service.icon} />
		</div>
		<h4 className="text-2xl font-medium mb-4" style={{ color: service.color }}>
			{service.title}
		</h4>
		<p className="opacity-80">{service.description}</p>
	</div>
);

ServiceItem.propTypes = {
	service: PropTypes.object.isRequired,
};

const Service6 = () => {
	return (
		<section className="ezy__service6 light py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
			<div className="container px-4 mx-auto">
				<div className="flex items-center justify-center mb-6 sm:mb-12">
					<div className="w-full max-w-xl text-center">
						<h2 className="text-3xl leading-none md:text-[45px] font-bold mb-4" style={{ color: "#00416A" }}>
							Our Services
						</h2>
						<p className="text-lg opacity-80 mb-6">
                        Our team of experts diligently curates and updates the latest profit rates, helping you find the best savings and investment opportunities
						</p>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-6">
					{serviceList.map((service, i) => (
						<div className="col-span-3 md:col-span-1" key={i}>
							<ServiceItem service={service} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Service6;
