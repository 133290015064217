import React , {useState} from 'react';
import PropTypes from 'prop-types';
import {Link } from 'react-router-dom';
import img1 from '../../assets/images/layout/banner-01.png'

import './Banner01.css'; // Import the CSS file


import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';
import Button from '../button';



// Banner01.propTypes = {
//     data: PropTypes.array,
// };

// function Banner01(props) {


//     const {data} = props;

//     const [dataBlock] = useState(
//         {
//             title: 'Welcome to Profit Comparison - ',
//             compass: 'Your Financial Compass!',
//             desc : 'Navigating the vast sea of banking options can be overwhelming. That’s where Profit Comparison steps in.',
//             // title2: 'Our Partners'
//         }
//     );
//     return (
//         <section className="banner">
//                 <div className="container">
//                     <div className="row">
//                     <div className="col-xl-6 col-md-12">
//                         <div className="banner__content new_banner">
//                         <h2 className="title">
//                 {dataBlock.title}{' '}
//                 <span className="compass">
//                   {dataBlock.compass}
//                 </span>
//               </h2>
//                         <p className="fs-20 desc">
//                             {dataBlock.desc}
//                         </p>
//                         <Button title='Compare now' path='/wallet' />
//                         <div className="partner">
//                             <h6>{dataBlock.title2}</h6>
//                             {/* <div className="partner__list">

//                             <Swiper
//                                 modules={[Navigation,  Scrollbar, A11y ]}
//                                     spaceBetween={65}
//                                     slidesPerView={4}
//                                     className="swiper-partner"
//                                 >
//                                 {
//                                     data.map(idx => (
//                                         <SwiperSlide key={idx.id}>
//                                             <Link to="#">
//                                                 <img src={idx.img} alt="Rockies"/>
//                                             </Link>
//                                         </SwiperSlide>
                                        
//                                     ))
//                                 }
//                             </Swiper>
//                             </div> */}
//                         </div>
//                         </div>
//                     </div>
//                     <div className="col-xl-6 col-md-12">
//                         <div className="banner__image">
//                         <img src={img1} alt="Rockie" />
//                         </div>
//                     </div>
//                     </div>
//                 </div>
//             </section>
//     );
// }

// export default Banner01;

Banner01.propTypes = {
    data: PropTypes.array,
};

function Banner01(props) {
    const { data } = props;

    const [dataBlock] = useState({
        title: 'Welcome to Profit Comparison - ',
        compass: 'Your Financial Compass!',
        desc: 'Navigating the vast sea of banking options can be overwhelming.\n That’s where Profit Comparison steps in.',
        // title2: 'Our Partners'
    });

    return (
        <section className="banner">
            <div className="container">
                <div className="banner__content new_banner">
                    <h2 className="title">
                        {dataBlock.title}{' '}
                        <span className="compass">
                            {dataBlock.compass}
                        </span>
                    </h2>
                    <p className="fs-20 desc">
                        {dataBlock.desc}
                    </p>
                    <Button className='custom-button' title='Compare now' path='/wallet' />
                    <div className="partner">
                        <h6>{dataBlock.title2}</h6>
                        {/* Partner content here */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner01;