// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Popup from '../Popup/Popup';
// import { Link } from 'react-router-dom';
// import logo from '../../assets/images/logo/logo.png';
// import { FaBars, FaTimes } from 'react-icons/fa';

// const routes = [
//   { name: 'Home', href: '/', isActive: true },
//   { name: 'Compare', href: '/wallet', isActive: true },
//   { name: 'Contact', href: '/contact', isActive: true },
//   { name: 'FAQ', href: '/faq', isActive: true },
// ];

// const NavMenu = ({ routes }) => (
//   <ul className="flex flex-col md:flex-row md:items-center">
//     {routes.map((route, i) => (
//       <li key={i} className="md:ml-4 mt-2 md:mt-0">
//         <Link
//           className={`px-4 ${
//             route.isActive ? 'text-[#00416A] opacity-100' : 'text-[#00416A] opacity-50 hover:opacity-100'
//           }`}
//           to={route.href}
//         >
//           {route.name}
//         </Link>
//       </li>
//     ))}
//   </ul>
// );

// const Header = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [bankData, setBankData] = useState([]);
//   const [question, setQuestion] = useState('');
//   const [options, setOptions] = useState([]);
//   const [correctAnswer, setCorrectAnswer] = useState('');
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   useEffect(() => {
//     const fetchBankData = async () => {
//       try {
//         const response = await axios.get('https://profit-backend.onrender.com/banks/');
//         const data = response.data;
//         setBankData(data);

//         // Randomly select 4 banks from the data
//         const shuffled = data.sort(() => 0.5 - Math.random());
//         const selectedOptions = shuffled.slice(0, 4);

//         // Calculate the bank with the highest profit rate among the selected options
//         const highestRateBank = selectedOptions.reduce((prev, current) => {
//           const prevProfitRate = parseFloat(prev.profitrate); // Convert string to float
//           const currentProfitRate = parseFloat(current.profitrate); // Convert string to float
//           return prevProfitRate > currentProfitRate ? prev : current;
//         });

//         // Set the question and options
//         setQuestion('Which bank offers the Highest profit rate?');
//         setOptions(selectedOptions.map(bank => bank.name));
//         setCorrectAnswer(highestRateBank.name);
//       } catch (error) {
//         console.error('Error fetching bank data', error);
//       }
//     };

//     fetchBankData();
//   }, []);

//   const handleShowPopup = () => {
//     setShowPopup(true);
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false); // Close the popup when 'Close' button is pressed
//   };

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <header className="bg-white shadow-sm fixed w-full z-10 top-0 ">
//       <div className="bg-[#00416A] text-white py-2 md:hidden">
//         <div className="container mx-auto px-4 flex justify-end">
//           <button
//             className="px-4 py-2 bg-white text-[#00416A] rounded hover:bg-opacity-90"
//             onClick={handleShowPopup}
//           >
//             Research Form
//           </button>
//         </div>
//       </div>
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center py-4">
//           <div className="flex items-center">
//             <Link to="/" className="text-[#00416A] text-lg font-bold">
//               <img src={logo} alt="Logo" />
//             </Link>
//             <nav className="ml-8 hidden md:block">
//               <NavMenu routes={routes} />
//             </nav>
//           </div>
//           <div className="md:hidden">
//             <button onClick={toggleMenu} className="text-[#00416A] focus:outline-none">
//               {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
//             </button>
//           </div>
//           <div className="hidden md:flex items-center">
//             <button
//               className="ml-4 px-6 py-2 bg-[#00416A] text-white rounded hover:bg-opacity-90"
//               onClick={handleShowPopup}
//             >
//               Research Form
//             </button>
//           </div>
//         </div>
//         {isMenuOpen && (
//           <div className="md:hidden">
//             <NavMenu routes={routes} />
//             <div className="flex justify-center mt-4">
//               <button
//                 className="px-6 py-2 bg-[#00416A] text-white rounded hover:bg-opacity-90"
//                 onClick={handleShowPopup}
//               >
//                 Research Form
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//       <Popup
//         show={showPopup}
//         onClose={handleClosePopup}
//         question={question}
//         options={options}
//         correctAnswer={correctAnswer} // Pass the correctAnswer prop
//       />
//     </header>
//   );
// };

// export default Header;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from '../Popup/Popup';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const routes = [
  { name: 'Home', href: '/', isActive: true },
  { name: 'Compare', href: '/wallet', isActive: true },
  { name: 'Contact', href: '/contact', isActive: true },
  { name: 'FAQ', href: '/faq', isActive: true },
  { name: 'Hiring', href: '/hiring', isActive: true },
];

const NavMenu = ({ routes, handleDashboardClick }) => (
  <ul className="flex flex-col md:flex-row md:items-center">
    {routes.map((route, i) => (
      <li key={i} className="md:ml-4 mt-2 md:mt-0">
        <Link
          className={`px-4 ${
            route.isActive ? 'text-[#00416A] opacity-100' : 'text-[#00416A] opacity-50 hover:opacity-100'
          }`}
          to={route.href}
        >
          {route.name}
        </Link>
      </li>
    ))}
    {/* Always render the Dashboard link */}
    <li className="md:ml-4 mt-2 md:mt-0">
      <a
        className="px-4 text-[#00416A] opacity-100 cursor-pointer"
        onClick={handleDashboardClick}
      >
        Job Portal
      </a>
    </li>
  </ul>
);

const Header = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem('secret_key') !== null && localStorage.getItem('accessToken') !== null;

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const response = await axios.get('https://profit-backend.onrender.com/api/banks/');
        const data = response.data;
        setBankData(data);

        const shuffled = data.sort(() => 0.5 - Math.random());
        const selectedOptions = shuffled.slice(0, 4);

        const highestRateBank = selectedOptions.reduce((prev, current) => {
          const prevProfitRate = parseFloat(prev.profitrate);
          const currentProfitRate = parseFloat(current.profitrate);
          return prevProfitRate > currentProfitRate ? prev : current;
        });

        setQuestion('Which bank offers the Highest profit rate?');
        setOptions(selectedOptions.map((bank) => bank.name));
        setCorrectAnswer(highestRateBank.name);
      } catch (error) {
        console.error('Error fetching bank data', error);
      }
    };

    fetchBankData();
  }, []);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDashboardClick = () => {
    if (isLoggedIn) {
      navigate('/panel');
    } else {
      alert('You need to login first!');
      navigate('/login');
    }
  };

  return (
    <header className="bg-white shadow-sm fixed w-full z-10 top-0 pt-3 pb-3">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="text-[#00416A] text-lg font-bold">
              <img src={logo} alt="Logo" />
            </Link>
            <nav className="ml-8 hidden md:block">
              <NavMenu routes={routes} handleDashboardClick={handleDashboardClick} />
            </nav>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-[#00416A] focus:outline-none">
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            {/* Sign in and Sign up buttons should be shown always */}
            <Link to="/login" className="text-[#00416A] hover:opacity-75">
              Sign in
            </Link>
            <button className="px-6 py-2 bg-[#00416A] text-white rounded hover:bg-opacity-90" onClick={handleShowPopup}>
              Sign up
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden">
            <NavMenu routes={routes} handleDashboardClick={handleDashboardClick} />
            <div className="flex justify-center mt-4 space-x-6">
              <Link to="/login" className="text-[#00416A] hover:opacity-75">
                Sign in
              </Link>
              <button className="px-6 py-2 bg-[#00416A] text-white rounded hover:bg-opacity-90" onClick={handleShowPopup}>
                Sign up
              </button>
            </div>
          </div>
        )}
      </div>
      <Popup
        show={showPopup}
        onClose={handleClosePopup}
        question={question}
        options={options}
        correctAnswer={correctAnswer}
      />
    </header>
  );
};

export default Header;


