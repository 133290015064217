
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/Popup/ProtectedRoute';
import ProtectedPage from './pages/Wallet';
import Profile from './pages/Profile';
import UserPanel from './pages/UserPanel';
import Header from './components/header1/Header';
import Chatbot from './components/Chatbot/Chatbot';
import Footer from './components/footer1/Footer';
import Login1 from './components/Login/Login'
import '../src/assets/font/font-awesome.css'
import routes from './pages';
import Page404 from './pages/404';
import ResearchFundingApplicationForm from './pages/Research-proposal';
import EssaySubmissionPage from './pages/EssaySubmissionPage';
import HiringFormPage from './pages/HiringForm';
import OpenApplications from './pages/OpenApplications';
import ResetPassword from './pages/ResetPassword';
import VideoEditor from './pages/Videoeditor';
import EducationForm from './pages/UserEducation';
import ExperienceForm from './pages/UserExperience';
import EditProfile from './pages/EditProfil';


function App() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

    return (
        <>

            <Header />

            <Routes>

                {
                    routes.map((data,idx) => (
                        <Route key={idx} path={data.path} element={data.component} exact />
                    ))
                }
                <Route path="/user" element={<Login1/>} />
                <Route path="/research-funding-application" element={<ResearchFundingApplicationForm />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/panel" element={<UserPanel />} />
                <Route path="/essay" element={<EssaySubmissionPage />} />
                <Route path="/open-application" element={<OpenApplications />} />
                <Route path="/hiring" element={<HiringFormPage />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/video-editor" element={<VideoEditor />} />
                <Route path="/education" element={<EducationForm />} />
                <Route path="/experience" element={<ExperienceForm />} />
                <Route path="/edit-profile" element={<EditProfile />} />
                {/* <ProtectedRoute path="/Wallet" element={<ProtectedPage />} /> */}
                <Route path='*' element={<Page404 />} />
            </Routes>
            <Chatbot />
            <Footer />
        </>
    );
}

export default App;
// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import axios from 'axios';
// import Header from './components/header';
// import Footer from './components/footer';
// import Popup from './components/Popup/Popup';
// import routes from './pages';
// import Page404 from './pages/404';
// import ProtectedPage from './pages/Wallet';
// import './App.scss';

// function App() {
//   const [showPopup, setShowPopup] = useState(false);
//   const [bankData, setBankData] = useState([]);
//   const [question, setQuestion] = useState('');
//   const [options, setOptions] = useState([]);
//   const [correctAnswer, setCorrectAnswer] = useState('');
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     AOS.init({ duration: 2000 });

//     const fetchBankData = async () => {
//       try {
//         const response = await axios.get('https://profit-backend.onrender.com/banks/');
//         const data = response.data;

//         // Shuffle and select 4 random banks
//         const shuffled = data.sort(() => 0.5 - Math.random());
//         const selectedOptions = shuffled.slice(0, 4);

//         // Find the bank with the highest profit rate
//         const highestRateBank = selectedOptions.reduce((prev, current) => (prev.profitrate > current.profitrate) ? prev : current);

//         setBankData(data);
//         setQuestion('Which bank offers the highest profit rate?');
//         setOptions(selectedOptions.map(bank => bank.name));
//         setCorrectAnswer(highestRateBank.name);
//       } catch (error) {
//         console.error('Error fetching bank data', error);
//       }
//     };

//     fetchBankData();
//   }, []);

//   const handleShowPopup = () => {
//     setShowPopup(true);
//   };

//   const handleClosePopup = (selectedOption) => {
//     if (selectedOption === correctAnswer) {
//       setIsAuthenticated(true);
//       setShowPopup(false);
//       window.location.href = '/Wallet'; // Adjust this to your correct path
//     } else {
//       alert('Incorrect! Please try again.');
//     }
//   };

//   return (
//     <Router>
//       <Header />
//       <Routes>
//         {routes.map((data, idx) => (
//           <Route key={idx} path={data.path} element={data.component} exact />
//         ))}
//         <Route path="*" element={<Page404 />} />
//         {isAuthenticated ? (
//           <Route path="/Wallet" element={<ProtectedPage />} />
//         ) : (
//           <Route path="/Wallet" element={<button onClick={handleShowPopup}>Access Protected Page</button>} />
//         )}
//       </Routes>
//       {showPopup && (
//         <Popup 
//           show={showPopup} 
//           onClose={handleClosePopup} 
//           question={question} 
//           options={options} 
//         />
//       )}
//       <Footer />
//     </Router>
//   );
// }

// export default App;


