

import img1 from '../images/icon/show.png'
import img2 from '../images/icon/done.png'
import img3 from '../images/icon/users.png'
const dataAbout = [
    {
        id: 1,
        title: 'Where should I invest my funds for the highest profit?',
        text: 'To find the best investment options for high profits, I recommend using our calculators to compare the profit rates of various banks. Please consult your financial consultant for personalized advice and ensure you have all necessary information before shifting your funds.',
        show: 'show'
    },
    {
        id: 2,
        title: 'What are the current profit rates for savings accounts?',
        text: 'The current profit rates for savings accounts vary by bank. You can view up-to-date comparisons using our website\'s calculators. Please consult your financial consultant and verify the rates before making any decisions. ',
    },
    {
        id: 3,
        title: 'How can I maximize my returns on a fixed deposit?',
        text: 'To maximize returns on a fixed deposit, compare the profit rates offered by different banks using our calculators. Please consult your financial consultant to choose the best option and gather all relevant information before shifting your funds. ',
    },
    {
        id: 4,
        title: 'Is it safe to transfer large sums of money to a new bank?',
        text: 'Transferring large sums of money should always be done with caution. Ensure you choose a reputable bank. Consult your financial consultant for advice and make sure you have all the necessary information before proceeding. ',
    },
    {
        id: 5,
        title: 'What are the best options for short-term investments?',
        text: 'For short-term investments, Term Deposit Receipts (TDRs) can be a good option. Use our calculators to compare rates from different banks. Please consult your financial consultant to choose the best option and gather all relevant information before shifting your funds. ',
    },
    {
        id: 6,
        title: 'Which bank offers the highest profit rate for a 1-year fixed deposit?',
        text: 'You can use our calculators to compare the profit rates for 1-year fixed deposits across different banks. I recommend consulting your financial consultant for the most accurate and personalized advice. Ensure you have all necessary information before transferring your funds. ',
    },

   
]

export default dataAbout;