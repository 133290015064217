import React from 'react';
import PageTitle from '../components/pagetitle';

function ResearchFundingApplicationForm() {
    return (
        <div>
            <PageTitle heading='Research Funding Application Form' title='Research Funding Application' />

            <section className="application-form py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-main bg-light p-4 rounded shadow">
                                <div className="block-text text-center mb-4">
                                    <h3 className="heading" style={{ fontSize: '1.5rem' }}>Research Funding Application Form</h3>
                                </div>

                                <form>
                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>1. Personal Information</h4>
                                    <div className="form-group mb-4">
                                        <label>Title</label><br />
                                        {['Mr.', 'Mrs.', 'Miss', 'Ms.'].map(title => (
                                            <label className="mr-3" key={title}>
                                                <input type="radio" name="title" value={title} /> {title} 
                                            </label>
                                        ))}
                                    </div>
                                    {['First Name', 'Last Name', 'Nationality', 'Phone Number', 'Email Address', 'Mailing Address'].map(placeholder => (
                                        <div className="form-group mb-4" key={placeholder}>
                                            <label>{placeholder}</label>
                                            <input type="text" className="form-control" placeholder={placeholder} />
                                        </div>
                                    ))}
                                    <div className="form-group mb-4">
                                        <label>Gender</label><br />
                                        {['Male', 'Female', 'Other'].map(gender => (
                                            <label className="mr-3" key={gender}>
                                                <input type="radio" name="gender" value={gender} /> {gender} 
                                            </label>
                                        ))}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Date of Birth (DD/MM/YYYY)</label>
                                        <input type="text" className="form-control" placeholder="DD/MM/YYYY" />
                                    </div>

                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>2. Academic Background</h4>
                                    {['Highest Degree Obtained', 'Field of Study', 'Institution', 'Year of Graduation', 'Other Degrees/Certifications'].map(field => (
                                        <div className="form-group mb-4" key={field}>
                                            <label>{field}</label>
                                            <input type="text" className="form-control" placeholder={field} />
                                        </div>
                                    ))}
                                    
                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>3. Professional Experience</h4>
                                    {['Current Position', 'Institution/Organization', 'Years of Experience', 'Previous Positions'].map(exp => (
                                        <div className="form-group mb-4" key={exp}>
                                            <label>{exp}</label>
                                            <input type="text" className="form-control" placeholder={exp} />
                                        </div>
                                    ))}
                                    <div className="form-group mb-4">
                                        <label>Duration</label>
                                        <input type="text" className="form-control" placeholder="Duration" />
                                    </div>

                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>4. Research Experience</h4>
                                    {['Previous Research Projects', 'Funding Source', 'Duration', 'Publications', 'Journal/Conference', 'Year', 'Awards and Honors', 'Institution'].map(res => (
                                        <div className="form-group mb-4" key={res}>
                                            <label>{res}</label>
                                            <input type="text" className="form-control" placeholder={res} />
                                        </div>
                                    ))}

                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>5. Research Proposal</h4>
                                    <div className="form-group mb-4">
                                        <label>Title of Proposed Research</label>
                                        <input type="text" className="form-control" placeholder="Title of Proposed Research" />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Abstract (Brief summary, max 300 words)</label>
                                        <textarea className="form-control" rows="3" placeholder="Abstract"></textarea>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Type of Research</label><br />
                                        {['Qualitative', 'Quantitative', 'Mixed Methods', 'Other'].map(type => (
                                            <label className="mr-3" key={type}>
                                                <input type="radio" name="typeOfResearch" value={type} /> {type} 
                                            </label>
                                        ))}
                                    </div>
                                    {['Objectives', 'Research Methodology', 'Expected Outcomes', 'Significance of the Research', 'Proposed Timeline'].map(proposal => (
                                        <div className="form-group mb-4" key={proposal}>
                                            <label>{proposal}</label>
                                            <textarea className="form-control" rows="2" placeholder={proposal}></textarea>
                                        </div>
                                    ))}
                                    {['Collaborators', 'Institution', 'Role'].map(collab => (
                                        <div className="form-group mb-4" key={collab}>
                                            <label>{collab}</label>
                                            <input type="text" className="form-control" placeholder={collab} />
                                        </div>
                                    ))}

                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>6. Budget Details</h4>
                                    <div className="form-group mb-4">
                                        <label>Total Funding Requested</label>
                                        <input type="text" className="form-control" placeholder="Total Funding Requested" />
                                    </div>
                                    {['Equipment', 'Travel', 'Personnel', 'Other'].map(budget => (
                                        <div className="form-group mb-4" key={budget}>
                                            <input type="text" className="form-control" placeholder={budget} />
                                        </div>
                                    ))}
                                    <div className="form-group mb-4">
                                        <label>Justification for Budget</label>
                                        <textarea className="form-control" rows="3" placeholder="Justification for Budget"></textarea>
                                    </div>

                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>7. Ethical Considerations</h4>
                                    <div className="form-group mb-4">
                                        <label>Ethical Approval</label><br />
                                        {['Approved', 'Pending', 'Not Required'].map(approval => (
                                            <label className="mr-3" key={approval}>
                                                <input type="radio" name="approvalStatus" value={approval} /> {approval} 
                                            </label>
                                        ))}
                                    </div>
                                    {['Ethics Committee/Institution', 'Approval Number'].map(ethics => (
                                        <div className="form-group mb-4" key={ethics}>
                                            <label>{ethics}</label>
                                            <input type="text" className="form-control" placeholder={ethics} />
                                        </div>
                                    ))}

                                    <h4 className="mb-3" style={{ fontSize: '1.25rem' }}>8. References</h4>
                                    {[1, 2].map(ref => (
                                        <div key={ref}>
                                            <div className="form-group mb-4">
                                                <label>Professional Reference {ref}</label>
                                                <input type="text" className="form-control" placeholder="Name" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>Position</label>
                                                <input type="text" className="form-control" placeholder="Position" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>Institution</label>
                                                <input type="text" className="form-control" placeholder="Institution" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>Contact Information</label>
                                                <input type="text" className="form-control" placeholder="Contact Information" />
                                            </div>
                                        </div>
                                    ))}

                                    <button type="submit" className="btn btn-primary mt-4">Submit Application</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ResearchFundingApplicationForm;
