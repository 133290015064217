import React from 'react';

const Footer = () => {
  return (
    <footer className="flex flex-col space-y-10 justify-center m-10">
      <nav className="flex justify-center flex-wrap gap-6 text-gray-500 font-medium">
        <a className="hover:text-gray-900" href="/">Home</a>
        <a className="hover:text-gray-900" href="/wallet">Compare</a>
        <a className="hover:text-gray-900" href="/contact">Contact</a>
        <a className="hover:text-gray-900" href="/faq">FAQ</a>
        {/* <a className="hover:text-gray-900" href="#">Gallery</a>
        <a className="hover:text-gray-900" href="#">Contact</a> */}
      </nav>

      <div className="flex justify-center space-x-5">
        <a href="https://www.facebook.com/profile.php?id=61562552880649&locale=en_GB" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/facebook-new.png" alt="Facebook" />
        </a>
        <a href="https://www.linkedin.com/company/profit-comparison/" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/linkedin-2.png" alt="LinkedIn" />
        </a>
        <a href="https://www.instagram.com/profitcomparison/" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" alt="Instagram" />
        </a>
        {/* <a href="https://messenger.com" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png" alt="Messenger" />
        </a> */}
        <a href="https://x.com/profitcompare" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/twitter.png" alt="Twitter" />
        </a>
      </div>

      <p className="text-center text-gray-700 font-medium">
        &copy; 2024 4teksolution. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
