
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const UserProfile = () => {
//   const [userData, setUserData] = useState(null); // State to hold user data
//   const [loading, setLoading] = useState(true); // State to handle loading state
//   const [error, setError] = useState(null); // State to handle errors
//   const navigate = useNavigate();

//   // Function to fetch user data from the backend
//   const fetchUserData = async () => {
//     const secretKey = localStorage.getItem('secret_key');
//     const token = localStorage.getItem('accessToken');

//     // Redirect to login if no secret_key or token is found
//     if (!secretKey || !token) {
//       navigate('/login');
//       return;
//     }

//     try {
//       const response = await fetch('http://localhost:8000/api/user-data/', {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'X-Secret-Key': secretKey,
//         },
//       });

//       if (response.status === 401) {
//         // Handle unauthorized access - token may have expired
//         console.error('Unauthorized - possible expired token.');
//         navigate('/login'); // Redirect to login
//         return;
//       }

//       if (!response.ok) {
//         throw new Error('Failed to fetch user data');
//       }

//       const data = await response.json();
//       setUserData(data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Use Effect hook to fetch user data on component mount
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   // Function to log out the user
//   const handleLogout = () => {
//     localStorage.removeItem('secret_key');
//     localStorage.removeItem('accessToken');
//     navigate('/login');
//   };

//   // Function to handle profile update
//   const handleUpdate = () => {
//     // Logic for updating user profile information
//     console.log('Profile updated successfully.');
//   };

//   // Render loading state
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   // Render error state
//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   // Render user profile information
//   return (
//     <div className="max-w-4xl mx-auto mt-16 mb-20 p-8 border border-gray-200 rounded-lg shadow-lg dark:bg-gray-900">
//       <h2 className="text-3xl font-bold text-center dark:text-gray-200">User Profile</h2>

//       {userData ? (
//         <form className="mt-8">
//           {/* First Name */}
//           <div className="mt-4">
//             <label className="block text-gray-600 dark:text-gray-300 mb-2">First Name</label>
//             <input
//               type="text"
//               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//               value={userData.first_name || ''}
//               onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
//             />
//           </div>

//           {/* Last Name */}
//           <div className="mt-4">
//             <label className="block text-gray-600 dark:text-gray-300 mb-2">Last Name</label>
//             <input
//               type="text"
//               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//               value={userData.last_name || ''}
//               onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
//             />
//           </div>

//           {/* Email */}
//           <div className="mt-4">
//             <label className="block text-gray-600 dark:text-gray-300 mb-2">Email</label>
//             <input
//               type="email"
//               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//               value={userData.email || ''}
//               onChange={(e) => setUserData({ ...userData, email: e.target.value })}
//             />
//           </div>

//           {/* Phone */}
//           <div className="mt-4">
//             <label className="block text-gray-600 dark:text-gray-300 mb-2">Phone</label>
//             <input
//               type="text"
//               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//               value={userData.phone || ''}
//               onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
//             />
//           </div>

//           {/* Nationality */}
//           <div className="mt-4">
//             <label className="block text-gray-600 dark:text-gray-300 mb-2">Nationality</label>
//             <select
//               className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
//               value={userData.nationality || ''}
//               onChange={(e) => setUserData({ ...userData, nationality: e.target.value })}
//             >
//               <option disabled>Select Nationality</option>
//               <option value="USA">USA</option>
//               <option value="Canada">Canada</option>
//               <option value="UK">UK</option>
//               {/* Add more nationalities as needed */}
//             </select>
//           </div>

//           {/* Update Button */}
//           <div className="w-full flex justify-center mt-6">
//             <button
//               type="button"
//               className="rounded-lg text-white bg-blue-700 text-lg px-10 py-2"
//               onClick={handleUpdate}
//             >
//               Update
//             </button>
//           </div>

//           {/* Logout Button */}
//           <div className="w-full flex justify-center mt-6">
//             <button
//               type="button"
//               className="rounded-lg text-white bg-red-700 text-lg px-10 py-2"
//               onClick={handleLogout}
//             >
//               Logout
//             </button>
//           </div>
//         </form>
//       ) : (
//         <p className="mt-4 text-center dark:text-gray-200">No user data found. Please log in again.</p>
//       )}
//     </div>
//   );
// };

// export default UserProfile;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchUserData = async () => {
    const secretKey = localStorage.getItem('secret_key');
    const token = localStorage.getItem('accessToken');

    if (!secretKey || !token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/user-data/', {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Secret-Key': secretKey,
        },
      });

      if (response.status === 401) {
        console.error('Unauthorized - possible expired token.');
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Function to handle profile update
  const handleUpdate = async () => {
    const token = localStorage.getItem('accessToken');
    const secretKey = localStorage.getItem('secret_key');
  
    if (!token || !secretKey) {
      console.error('Missing authentication details.');
      return;
    }
  
    try {
      const response = await fetch('http://127.0.0.1:8000/api/user-data/', {
        method: 'PUT', // Changed to PUT method
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'X-Secret-Key': secretKey,
        },
        body: JSON.stringify(userData), // Ensure all fields are included
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user data');
      }
  
      console.log('Profile updated successfully.');
      alert('Profile updated successfully.');
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Failed to update profile.');
    }
  };

  // Function to log out the user
  const handleLogout = () => {
    // Clear local storage and redirect to login
    localStorage.removeItem('secret_key');
    localStorage.removeItem('accessToken');
    navigate('/login');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto mt-16 mb-20 p-8 border border-gray-200 rounded-lg shadow-lg dark:bg-gray-900">
      <h2 className="text-3xl font-bold text-center dark:text-gray-200">User Profile</h2>

      {userData ? (
        <form className="mt-8 space-y-6">
          {[
            { label: 'First Name', type: 'text', field: 'first_name' },
            { label: 'Last Name', type: 'text', field: 'last_name' },
            { label: 'Gender', type: 'text', field: 'gender' },
            { label: 'Phone', type: 'text', field: 'phone' },
            { label: 'Country', type: 'text', field: 'nationality' },
          ].map((input) => (
            <div className="mt-4" key={input.field}>
              <label className="block text-gray-600 dark:text-gray-300 mb-2">{input.label}</label>
              <input
                type={input.type}
                className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
                value={userData[input.field] || ''}
                onChange={(e) => setUserData({ ...userData, [input.field]: e.target.value })}
              />
            </div>
          ))}

          {/* <div className="mt-4">
            <label className="block text-gray-600 dark:text-gray-300 mb-2">Nationality</label>
            <select
              className="p-4 w-full border-2 rounded-lg dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
              value={userData.nationality || ''}
              onChange={(e) => setUserData({ ...userData, nationality: e.target.value })}
            >
              <option disabled>Select Nationality</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
              <option value="UK">UK</option>
            </select>
          </div> */}

          <div className="w-full flex justify-center mt-6 space-x-4">
            <button
              type="button"
              className="rounded-lg text-white bg-blue-700 text-lg px-10 py-2"
              onClick={handleUpdate}
            >
              Update
            </button>
            <button
              type="button"
              className="rounded-lg text-white bg-red-700 text-lg px-10 py-2"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </form>
      ) : (
        <p className="mt-4 text-center dark:text-gray-200">No user data found. Please log in again.</p>
      )}
    </div>
  );
};

export default UserProfile;